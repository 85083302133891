import MyAgencies from './'
import { PageRoute } from '../../routes/routerInterface'
import { checkProdSite } from '../../helpers/checkProdSite'

const myAgencies: PageRoute[] = [
  {
    path: '/my-agencies',
    element: MyAgencies,
    isProtected: true,
    permission: checkProdSite(true, 'Developer'),
  },
]

export default myAgencies
