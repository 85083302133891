import { gql } from '@apollo/client'

export const REQUEST_AN_ACCOUNT = gql`
  mutation CreateAccount(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
    $businessSponsorFirstName: String!
    $businessSponsorLastName: String!
    $businessSponsorEmail: String!
    $businessSponsorPhoneNumber: String
  ) {
    createSandboxAccount(
      data: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phoneNumber: $phoneNumber
        businessSponsorFirstName: $businessSponsorFirstName
        businessSponsorLastName: $businessSponsorLastName
        businessSponsorEmail: $businessSponsorEmail
        businessSponsorPhoneNumber: $businessSponsorPhoneNumber
      }
    ) {
      userId
      firstName
      lastName
      email
      phoneNumber
      businessSponsorFirstName
      businessSponsorLastName
      businessSponsorEmail
      businessSponsorPhoneNumber
    }
  }
`
