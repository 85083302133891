import React, { FC, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'

import BaseDialog from '../../../components/dialog'
import ButtonBase from '../../../components/partials/button'
import { FormInput } from '../../../components/partials/formInput'
import { FormInputPhoneNumber } from '../../../components/partials/formInputPhoneNumber'
import { RootState } from '../../../store'
import { setSpamming } from '../../../redux/actions/users'
import { REQUEST_AN_ACCOUNT } from '../../../queries/quickStart'
import { notify } from '../../../components/toastify'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { alphabetPattern, emailPattern, americanPhoneNumberPattern } from '../../../utils/regex'
import iconTimes from '../../../assets/images/icon-times.svg'
import { useSession } from '../../../helpers/checkSession'
import { customStyles } from '../../../constants/customStyles'

export type RegistrationFormFields = {
  businessSponsorFirstName: string
  businessSponsorLastName: string
  businessSponsorEmail: string
  businessSponsorPhoneNumber?: string
  firstName: string
  lastName: string
  email: string
  phoneNumber?: string
}

type Props = {
  handleCloseDialog?: (val: boolean) => void
  modalIsOpen?: boolean
}

const RequestAnAccount: FC<Props> = ({ handleCloseDialog = () => null, modalIsOpen }) => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm<RegistrationFormFields>()
  let captcha: ReCAPTCHA
  const googleSiteKey = process.env.REACT_APP_PRODUCTION_GG_SITE_KEY
  const dispatch = useDispatch()
  const { handleErrorSession } = useSession()
  const spammingCounting = useSelector((state: RootState) => state.userReducers?.spammingCount)
  const [requestAccount] = useMutation(REQUEST_AN_ACCOUNT)
  const [isInvalid, setIsInvalid] = useState(false)

  const onSubmit = handleSubmit(async (data) => {
    const variables = {
      businessSponsorFirstName: data?.businessSponsorFirstName.trim(),
      businessSponsorLastName: data?.businessSponsorLastName.trim(),
      businessSponsorEmail: data?.businessSponsorEmail.trim(),
      businessSponsorPhoneNumber: data?.businessSponsorPhoneNumber,
      firstName: data?.firstName.trim(),
      lastName: data?.lastName.trim(),
      email: data?.email.trim(),
      phoneNumber: data?.phoneNumber,
    }
    try {
      const dataRes = await requestAccount({ variables: variables })
      if (dataRes) {
        notify(MESSAGES.SUCCESS.S_CREATED_ACCOUNT, STATUS.SUCCESS)
        handleCloseDialog(false)
        reset()
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_CREATE_ACCOUNT)
      // handle count spamming when the user requests account fail
      dispatch(setSpamming({ count: spammingCounting + 1, date: new Date().getTime() }))
      resetCaptcha()
    }
  })

  const setCaptchaRef = (ref: ReCAPTCHA | null) => {
    if (ref) {
      return (captcha = ref)
    }
  }

  const resetCaptcha = () => {
    captcha.reset()
  }

  const handleCloseRequestAccountDialog = () => {
    handleCloseDialog(false)
    reset()
  }

  const handleCheckReCaptcha = () => {
    setIsInvalid(false)
  }

  const handleTrimValues = () => {
    setValue('businessSponsorFirstName', getValues('businessSponsorFirstName').trim())
    setValue('businessSponsorLastName', getValues('businessSponsorLastName').trim())
    setValue('businessSponsorEmail', getValues('businessSponsorEmail').trim())
    setValue('firstName', getValues('firstName').trim())
    setValue('lastName', getValues('lastName').trim())
    setValue('email', getValues('email').trim())
  }

  const handleSubmitForm = async () => {
    await handleTrimValues()
    await onSubmit()
  }

  useEffect(() => {
    if (spammingCounting >= 5) {
      setIsInvalid(true)
    }
  }, [spammingCounting])

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={customStyles}>
      <div className="w-[846px] px-4">
        <div className="flex justify-between">
          <div />
          <img
            src={iconTimes}
            alt=""
            className="cursor-pointer mr-[-12px]"
            onClick={handleCloseRequestAccountDialog}
          />
        </div>
        <p className="mb-2 font-semibold text-headline3">Request an account</p>
        <hr className="mt-4 mb-8 w-[812px]" />
        <p className="mb-4 text-headline5A">Business management information</p>
        <form onSubmit={handleSubmitForm}>
          <div className="grid grid-cols-2 gap-2">
            <div className="mt-2">
              <p className="mb-2 font-semibold text-hairline1">First name</p>
              <FormInput<RegistrationFormFields>
                id="businessSponsorFirstName"
                type="text"
                name="businessSponsorFirstName"
                label="First Name"
                placeholder="Enter your first name"
                className="mb-2"
                inputClassName="w-[380px] mt-1"
                register={register}
                rules={{
                  required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                  pattern: alphabetPattern,
                  maxLength: 200,
                }}
                errors={errors}
              />
            </div>
            <div className="mt-2 ml-6">
              <p className="mb-2 font-semibold text-hairline1">Last name</p>
              <FormInput<RegistrationFormFields>
                id="businessSponsorLastName"
                type="text"
                name="businessSponsorLastName"
                label="Last Name"
                placeholder="Enter your last name"
                className="mb-2"
                inputClassName="w-[380px] mt-1"
                register={register}
                rules={{
                  required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                  pattern: alphabetPattern,
                  maxLength: 200,
                }}
                errors={errors}
              />
            </div>
            <div className="mt-2">
              <p className="mb-2 font-semibold text-hairline1">Business email</p>
              <FormInput<RegistrationFormFields>
                id="businessSponsorEmail"
                type="email"
                name="businessSponsorEmail"
                label="Email Address"
                placeholder="Enter email with your company domain"
                className="mb-2"
                inputClassName="w-[380px] mt-1"
                register={register}
                rules={{
                  required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                  pattern: emailPattern,
                  maxLength: 200,
                }}
                errors={errors}
              />
            </div>
            <div className="mt-2 ml-6">
              <span className="mb-2 font-semibold text-hairline1">Phone number</span>{' '}
              <span className="text-body3">(optional)</span>
              <FormInputPhoneNumber<RegistrationFormFields>
                control={control}
                id="businessSponsorPhoneNumber"
                type="text"
                name="businessSponsorPhoneNumber"
                label="Phone number"
                placeholder="Enter your phone number "
                className="mb-2"
                inputClassName="w-[380px] mt-1"
                register={register}
                rules={{ required: false, pattern: americanPhoneNumberPattern }}
                errors={errors}
                value={getValues().businessSponsorPhoneNumber}
              />
            </div>
          </div>
          <hr className="mt-4 mb-6 w-[812px]" />
          <p className="mb-4 text-headline5A">Requester’s information</p>
          <div className="grid grid-cols-2 gap-2">
            <div className="mt-2">
              <p className="mb-2 font-semibold text-hairline1">First name</p>
              <FormInput<RegistrationFormFields>
                id="firstName"
                type="text"
                name="firstName"
                label="First Name"
                placeholder="Enter your first name"
                className="mb-2"
                inputClassName="w-[380px] mt-1"
                register={register}
                rules={{
                  required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                  pattern: alphabetPattern,
                  maxLength: 200,
                }}
                errors={errors}
              />
            </div>
            <div className="mt-2 ml-6">
              <p className="mb-2 font-semibold text-hairline1">Last name</p>
              <FormInput<RegistrationFormFields>
                id="lastName"
                type="text"
                name="lastName"
                label="Last Name"
                placeholder="Enter your last name"
                className="mb-2"
                inputClassName="w-[380px] mt-1"
                register={register}
                rules={{
                  required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                  pattern: alphabetPattern,
                  maxLength: 200,
                }}
                errors={errors}
              />
            </div>
            <div className="mt-2">
              <p className="mb-2 font-semibold text-hairline1">Business email</p>
              <FormInput<RegistrationFormFields>
                id="email"
                type="email"
                name="email"
                label="Email Address"
                placeholder="Enter email with your company domain"
                className="mb-2"
                inputClassName="w-[380px] mt-1"
                register={register}
                rules={{
                  required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                  pattern: emailPattern,
                  maxLength: 200,
                }}
                errors={errors}
              />
            </div>
            <div className="mt-2 ml-6">
              <p className="mb-2 font-semibold text-hairline1">Phone number</p>
              <FormInputPhoneNumber<RegistrationFormFields>
                control={control}
                id="phoneNumber"
                type="text"
                name="phoneNumber"
                label="Phone number"
                placeholder="Enter your phone number "
                className="mb-2"
                inputClassName="w-[380px] mt-1"
                register={register}
                rules={{
                  required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                  pattern: americanPhoneNumberPattern,
                }}
                errors={errors}
                value={getValues().phoneNumber}
              />
            </div>
          </div>
          <hr className="mt-4 mb-2 w-[812px]" />
        </form>
        {spammingCounting >= 5 && (
          <ReCAPTCHA
            ref={(r) => setCaptchaRef(r)}
            sitekey={`${googleSiteKey}`}
            onChange={handleCheckReCaptcha}
          />
        )}
        <ButtonBase
          type="submit"
          className="w-[365px] mb-6 mt-4 bg-primary-1 min-w-min text-center"
          onClick={handleSubmitForm}
          disabled={isInvalid}
          bgDisabled="bg-primary-shade3"
        >
          Submit
        </ButtonBase>
      </div>
    </BaseDialog>
  )
}

export default RequestAnAccount
