import React, { useEffect } from 'react'
import { useMutation } from '@apollo/client'

import ButtonBase from '../../../components/partials/button'
import BaseDialog from '../../../components/dialog'
import iconTimes from '../../../assets/images/icon-times.svg'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { notify } from '../../../components/toastify'
import { useSession } from '../../../helpers/checkSession'
import { customStyles } from '../../../constants/customStyles'
import {
  DELETE_CLIENT_CREDENTIAL_DEV,
  UPDATE_WEBHOOK_PASSWORD,
} from '../../../queries/developerPortal'
import { LocationState } from '../../../constants/interface'
import {
  DELETE_CLIENT_CREDENTIAL_ADMIN,
  UPDATE_WEBHOOK_PASSWORD_BY_ADMIN,
} from '../../../queries/adminPortal'
import { statusErrors } from '../../../constants/statusCode'

interface PropsAction {
  modalOpenSwitchBasicAuthOrAuth0?: boolean
  hiddenBlock?: string
  isAdmin?: boolean
  webhookId?: string
  locationState?: LocationState
  isSwitchAuth0?: boolean
  passwordValue?: string
  webhookPasswordVal?: string
  handleEdit?: () => void
  handleCloseDialog?: (val: boolean) => void
  handleRefetchWhenAddOrDeleteAuth0?: () => void
  handleCreateClientCredential?: () => void
  handleProceedAuth0?: () => void
  refetchGetWebhookInfo?: () => void
  refetchAuth0?: () => void
}

const SwitchBasicAuthOrAuth0: React.FunctionComponent<PropsAction> = ({
  modalOpenSwitchBasicAuthOrAuth0,
  isAdmin,
  webhookId,
  locationState,
  isSwitchAuth0,
  passwordValue,
  webhookPasswordVal,
  handleCloseDialog = () => null,
  handleRefetchWhenAddOrDeleteAuth0 = () => null,
  refetchGetWebhookInfo = () => null,
  refetchAuth0 = () => null,
  handleCreateClientCredential = () => null,
  handleProceedAuth0 = () => null,
}) => {
  const { handleErrorSession } = useSession()
  const [deleteClientCredential, { data: deleteClientCredentialData }] = useMutation(
    isAdmin ? DELETE_CLIENT_CREDENTIAL_ADMIN : DELETE_CLIENT_CREDENTIAL_DEV,
  )

  //update password
  const [updatePassword] = useMutation(
    isAdmin ? UPDATE_WEBHOOK_PASSWORD_BY_ADMIN : UPDATE_WEBHOOK_PASSWORD,
  )

  // handle update webhook password
  const handleUpdateWebhookPassword = async (updatePasswordVariable: object) => {
    try {
      const dataRes = await updatePassword(updatePasswordVariable)
      if (
        dataRes &&
        !statusErrors.includes(
          dataRes?.data?.updateWebhookPassword?.statusCode ||
            dataRes?.data?.updateWebhookPasswordByAdmin?.statusCode,
        )
      ) {
        notify(MESSAGES.SUCCESS.S_WEBHOOK_SECURITY, STATUS.SUCCESS)
      }
      refetchGetWebhookInfo()
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_PASSWORD_UPDATE_WEBHOOK)
    }
  }

  // proceed to update webhook password & delete client credential
  const handleProceedBasicAuth = async () => {
    const webhookPasswordVariable: object = isAdmin
      ? {
          variables: {
            data: {
              password: passwordValue,
              partyId: locationState?.partyId,
              kind: locationState?.kind,
            },
            webhookId: webhookId,
          },
        }
      : {
          variables: {
            data: {
              password: passwordValue,
            },
            webhookId: webhookId,
          },
        }
    const deleteClientCredVariablesAdmin = {
      data: {
        kind: locationState?.kind,
        partyId: locationState?.partyId,
      },
    }
    try {
      handleUpdateWebhookPassword(webhookPasswordVariable)
      const dataRes = (await isAdmin)
        ? deleteClientCredential({ variables: deleteClientCredVariablesAdmin })
        : (deleteClientCredential() as object)
      if (dataRes) {
        handleCloseDialog(false)
        refetchAuth0()
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_DELETE_CLIENT_CREDENTIAL)
    }
  }

  // proceed to create client credential & delete webhook password
  const handleCreateClientCredentialAndDeleteWebhookPassword = async () => {
    await handleCreateClientCredential()
    if (webhookPasswordVal) {
      await handleProceedAuth0()
      await refetchGetWebhookInfo()
    } else {
      await refetchGetWebhookInfo()
    }
  }

  useEffect(() => {
    if (!deleteClientCredentialData?.deleteClientCredentialForAdmin?.clientId) {
      handleRefetchWhenAddOrDeleteAuth0()
    }
  }, [deleteClientCredentialData])

  const handleCloseProceedAuth0Dialog = () => {
    handleCloseDialog(false)
  }

  return (
    <BaseDialog modalIsOpen={modalOpenSwitchBasicAuthOrAuth0} customStyles={customStyles}>
      <div className="w-[392px] h-[218px] p-2.5 pt-0">
        <div className="flex justify-between">
          <div />
          <img
            src={iconTimes}
            alt=""
            className="cursor-pointer mr-[-12px]"
            onClick={handleCloseProceedAuth0Dialog}
          />
        </div>
        <p className="text-center text-headline5 font-semibold mb-2 mt-[-16px] text-indicator-red">
          Switch to {!isSwitchAuth0 ? 'Basic Auth' : 'Auth0'} credentials
        </p>
        <p className="text-center text-body1 font-semibold mb-2 pt-4 text-indicator-red">
          Are you sure you want to do this?
        </p>
        <p className="text-center text-body1 font-semibold mb-2 mt-[-8px] text-indicator-red">
          If you proceed your {!isSwitchAuth0 ? '"Auth0"' : '"Basic Auth"'} credentials will be
          deleted
        </p>
        <div className="overflow-y-scroll cus-scrollbar mr-[-23px] "></div>
        <div className="flex w-[420px] pb-0">
          <ButtonBase
            type="submit"
            className="w-[180px] mt-4 bg-primary-1 min-w-min text-center"
            onClick={handleCloseProceedAuth0Dialog}
            bgDisabled="bg-primary-shade3"
          >
            Cancel
          </ButtonBase>
          <ButtonBase
            type="submit"
            className="w-[180px] mt-4 ml-3 justify-around border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4"
            onClick={
              !isSwitchAuth0
                ? handleProceedBasicAuth
                : handleCreateClientCredentialAndDeleteWebhookPassword
            }
            bgDisabled="bg-primary-shade3"
          >
            Proceed
          </ButtonBase>
        </div>
      </div>
    </BaseDialog>
  )
}

export default SwitchBasicAuthOrAuth0
