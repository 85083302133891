import React, { useState } from 'react'
import UpToTop from '../../components/partials/upToTop'

const Home = () => {
  const [isShowUpToTop, setIsShowUpToTop] = useState<boolean>(false)

  window.addEventListener('scroll', () => {
    if (window.scrollY > 250) {
      setIsShowUpToTop(true)
    } else {
      setIsShowUpToTop(false)
    }
  })

  return (
    <div className="mt-4 px-20 md:px-10 text-body1-med font-medium">
      <p className="mt-6 font-semibold">
        Welcome to the first release of our Propeller Bonds Developer Portal.
      </p>
      <p className="mt-6">
        This website allows you to build against a sandbox and provides features to all you to
        confirm and test your code before accessing production and understanding how our solution
        works and requires technical software skills to use it. You will need to be familiar with
        general approaches used HTTP/HTTPS protocols, be aware of what a web hook is and how to use
        it and some standard cryptographic and authentication technologies and approaches using JWT
        - the actual API is GraphQL which is very easy to learn.
      </p>
      <p className="mt-6">The Quick Start page tells you what to do to get access.</p>
      <p className="mt-6">
        We use Apollo GraphQL and the security of the Google cloud platform to protect the integrity
        of our systems and data so your access will be via provided accounts.
      </p>
      <p className="mt-6">
        Once signed up - you will gain access to the developer environment including specifics about
        how to connect up to our servers and authenticate and authorize access, and some tools and
        logging functionality to test our webhook approach.
      </p>
      <p className="mt-6">
        This is our first release of this platform so there are some idiosyncrasies of the platform
        that we remediate over time. As we made the decision to release a functional product rather
        than perfect one. (Like the requirement for a Graphql ID to use the sandbox explorer you
        will require a github account or setup a graphql id.)
      </p>
      <p className="mt-6">
        It’s worth your while just looking through the features of the explorer as it can generate a
        curl equivalent command you can just copy and paste into your code. As well as the field
        descriptions embedded in the explorer.
      </p>
      <p className="mt-6">
        Finally make sure you understand our transaction model as it is at the core of your
        integration. Make sure you understand what the transaction types are and how they work. We
        have created this model as an amalgam of requests from many Carriers and should be broad
        enough to satisfy your needs without being overly complicated.
      </p>
      <p className="mt-6">
        As always at Propeller we appreciate feedback so if you of you have any please send it to us
        at our email address below.
      </p>
      <div className="mt-10" />
      {isShowUpToTop && <UpToTop />}
    </div>
  )
}

export default Home
