import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import {
  SET_LOADING,
  SET_UNLOADING,
  LOGIN,
  LOGOUT,
  SET_USER_INFO,
  CLEAR_USER_INFO,
  SET_SPAMMING,
  CLEAR_SPAMMING,
} from '../actions/actionTypes'

export type usersState = {
  loading: boolean
  userInfo: any
  isLoggedIn: boolean
  spammingCount: number
  spammingTime: number
  isLoginFail: boolean
}

const initialState: usersState = {
  loading: false,
  userInfo: null,
  isLoggedIn: false,
  spammingCount: 0,
  spammingTime: 0,
  isLoginFail: true,
}

const userReducers = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      }
    case SET_UNLOADING:
      return {
        ...state,
        loading: false,
      }
    // USER INFO
    case LOGIN:
      return {
        ...state,
        isLoggedIn: true,
      }
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      }
    case SET_USER_INFO:
      return {
        ...state,
        userInfo: payload,
      }
    case CLEAR_USER_INFO:
      return {
        ...state,
        userInfo: null,
      }
    // SPAMMING
    case SET_SPAMMING:
      return {
        ...state,
        spammingCount: payload?.count,
        spammingTime: payload?.date,
      }
    case CLEAR_SPAMMING:
      return {
        ...state,
        spammingCount: 0,
        spammingTime: 0,
      }
    default:
      return state
  }
}

const persistConfig = {
  key: 'auth',
  storage: storage,
  whiteList: ['userInfo', 'isLoggedIn', 'spammingCount'],
  blacklist: ['loading'],
}

// export default userReducers
export default persistReducer(persistConfig, userReducers)
