import React, { useState } from 'react'
import SubjectManagement from './components/subject-config-table'
import GAListTable from './components/job-data-table'
import EmailConfigurationListTable from './components/email-configuration-table'
const EmailSystemManagement = () => {
  const [isRefetchList, setIsRefetchList] = useState(true)
  return (
    <div className="px-20 py-10 md:px-10">
      <GAListTable isRefetchList={isRefetchList} setIsRefetchList={setIsRefetchList} />
      <hr className="my-10 border-t-neutral-6" />
      <SubjectManagement />
      <hr className="my-10 border-t-neutral-6" />
      <EmailConfigurationListTable tableName="Banned List Setting" type="AVOID_SETTING" />
      <hr className="my-10 border-t-neutral-6" />
      <EmailConfigurationListTable tableName="T-Day Setting" type="DATE_SETTING" />
    </div>
  )
}

export default EmailSystemManagement
