import React, {  useState } from 'react'
import DatePicker from 'react-date-picker'
import iconCalendar from '../../../../../assets/images/icon-calendar.svg'
import iconTimes from '../../../../../assets/images/icon-times.svg'


interface Props {
  setCallBackDateChange?: (value: Date) => void
  minDate?: Date
  maxDate?: Date
}

const DateFilterPicker: React.FC<Props> = ({
  setCallBackDateChange = () => null,
  minDate,
  maxDate,
}) => {
  const [value, setValue] = useState<Date>()

  /**
   *
   */

  /**
   *
   * @param event
   */
  const onChange = (event: Date) => {
    setValue(event)
    setCallBackDateChange(event)
  }

  return (
    <div>
      <DatePicker
        onChange={onChange}
        value={value}
        format={'yyyy-MM-dd'}
        className={`w-full h-[58px] rounded-xl`}
        calendarIcon={<img src={iconCalendar} width={24} height={24} />}
        clearIcon={value ? <img src={iconTimes} width={24} height={24} /> : <React.Fragment />}
        minDate={minDate}
        maxDate={maxDate}
      />
    </div>
  )
}

export default DateFilterPicker
