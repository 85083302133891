export const LocalStorageKey = {
  ACCESS_TOKEN: 'accessToken',
  USER_INFO: 'userInfo',
  ACCESS_TOKEN_GG: 'accessTokenGg',
}

export const saveDataToLocalStore = (key: string, data: any) => {
  localStorage.setItem(key, data)
}

export const getDataToLocalStore = (key: string) => {
  localStorage.getItem(key)
}

export const clearDataToLocalStore = () => {
  localStorage.clear()
}

export const removeDataToLocalStore = (key: string) => {
  localStorage.removeItem(key)
}
