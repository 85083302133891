import React, { useEffect, useState } from 'react'
import iconTrashActive from '../../assets/images/icon-trash-active.svg'
import iconUnlock from '../../assets/images/icon-unlock.svg'
import iconLock from '../../assets/images/icon-lock.svg'
import iconChangeKeyName from '../../assets/images/icon-change-key-name.svg'
import classNames from 'classnames'
import EditUserDialog from './../../pages/my-account/dialog/editUser'
import EditAccountInfoDialog from '../../pages/admin-portal/dialog/editAccountInfo'
import UserListDialog from '../../pages/users-management/components/UserListDialog'
import {
  ContentDialog,
  contentDlgInterface,
  TitleDialog,
  TxtAction,
} from '../../pages/users-management/components/interface'
import { useMutation } from '@apollo/client'
import { clientDev, clientProd } from '../../network/apolloClient'
import {
  UPDATE_BLOCK_USER,
  UPDATE_BLOCK_USER_PRODUCTION,
  DELETE_USER,
  DELETE_USER_PRODUCTION,
} from '../../queries/adminPortal'
import { MESSAGES, STATUS } from '../../constants/messages'
import { notify } from '../toastify'
import { useSession } from '../../helpers/checkSession'

export type EditFormFields = {
  firstName: string
  lastName: string
}

const eDivClasses = classNames(
  `flex items-center p-2 hover:bg-neutral-7 hover:rounded-lg pointer-events-auto`,
)
interface PropsAction {
  hiddenBlock?: string
  handleCloseDialog?: (val: boolean) => void
  callback?: () => void
  handleClose?: () => void
  isEditCarrierInfo?: boolean
  userInfo?: object | any
  isBusinessInfo?: boolean
  handleRefetchData?: () => void
  isJustEdit?: boolean
  handleShowOverflow?: () => void
}

const RowEditUser: React.FunctionComponent<PropsAction> = ({
  hiddenBlock,
  callback,
  handleClose = () => null,
  isEditCarrierInfo,
  userInfo,
  isBusinessInfo,
  handleRefetchData = () => null,
  isJustEdit,
  handleShowOverflow = () => null,
}) => {
  const { handleErrorSession } = useSession()
  const [modalIsOpen, setIsModelOpen] = useState<boolean>(false)
  const [modalIsOpenEditCarrierInfo, setIsModelOpenEditCarrierInfo] = useState<boolean>(false)
  const [isOpenActionsDialog, setIsOpenActionsDialog] = useState<boolean>(false)
  const [txtAction, setTxtAction] = useState<TxtAction>(TxtAction.BLOCK)

  const [updateBlockUser] = useMutation(UPDATE_BLOCK_USER, {
    client: clientDev,
  })
  const [updateBlockUserProduction] = useMutation(UPDATE_BLOCK_USER_PRODUCTION, {
    client: clientProd,
  })
  const [deleteUser] = useMutation(DELETE_USER, {
    client: clientDev,
  })
  const [deleteUserProduction] = useMutation(DELETE_USER_PRODUCTION, {
    client: clientProd,
  })

  const handleBlockUser = async () => {
    setIsModelOpen(false)
    try {
      const resDev: any = await updateBlockUser({
        variables: {
          isBlocked: contentDialog[txtAction].txtBtn === TxtAction.BLOCK ? true : false,
          userId: userInfo.requesterId,
        },
      })
      await updateBlockUserProduction({
        variables: {
          requesterId: userInfo.requesterId,
          isBlocked: contentDialog[txtAction].txtBtn === TxtAction.BLOCK ? true : false,
        },
      })
      if (resDev?.data?.updateBlockUser && contentDialog[txtAction].txtBtn === TxtAction.BLOCK) {
        notify(MESSAGES.SUCCESS.S_BLOCK_USER, STATUS.SUCCESS)
        setIsOpenActionsDialog(false)
      }
      if (resDev?.data?.updateBlockUser && contentDialog[txtAction].txtBtn === TxtAction.UNBLOCK) {
        notify(MESSAGES.SUCCESS.S_UNBLOCK_USER, STATUS.SUCCESS)
        setIsOpenActionsDialog(false)
      }
      if (resDev?.data?.updateBlockUser) {
        handleRefetchData()
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_BLOCK_USER)
    }
  }
  const handleDeleteUser = async () => {
    setIsModelOpen(false)
    try {
      const resDev: any = await deleteUser({ variables: { userId: userInfo.requesterId } })
      await deleteUserProduction({
        variables: {
          requesterId: userInfo.requesterId,
        },
      })
      if (resDev?.data?.deleteUser) {
        notify(MESSAGES.SUCCESS.S_DELETE_USER, STATUS.SUCCESS)
        setIsOpenActionsDialog(false)
      }
      if (resDev?.data?.deleteUser) {
        handleRefetchData()
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_DELETE_USER)
    }
  }

  const contentDialog: contentDlgInterface = {
    block: {
      title: TitleDialog.BLOCK,
      content: ContentDialog.BLOCK,
      txtBtn: TxtAction.BLOCK,
      handleAction: handleBlockUser,
    },
    unblock: {
      title: TitleDialog.UNBLOCK,
      content: ContentDialog.UNBLOCK,
      txtBtn: TxtAction.UNBLOCK,
      handleAction: handleBlockUser,
    },
    delete: {
      title: TitleDialog.DELETE,
      content: ContentDialog.DELETE,
      txtBtn: TxtAction.DELETE,
      handleAction: handleDeleteUser,
    },
  }

  const handleShowActionsDialog = (txt: TxtAction) => {
    setTxtAction(txt)
    setIsOpenActionsDialog(true)
  }

  const handleCloseActionsDialog = () => {
    setIsOpenActionsDialog(false)
  }

  const openDialog = () => {
    setIsModelOpen(true)
    callback && callback()
  }

  const openDialogEditCarrierInfo = () => {
    setIsModelOpenEditCarrierInfo(true)
    callback && callback()
  }

  useEffect(() => {
    if (!modalIsOpen || !modalIsOpenEditCarrierInfo || !isOpenActionsDialog) {
      handleShowOverflow()
    }
  }, [modalIsOpen, modalIsOpenEditCarrierInfo, isOpenActionsDialog])

  return (
    <div className="h-auto w-60 border border-neutral-7 bg-neutral-8 shadow-2xl rounded-xl mt-[10px]">
      <div className="px-2">
        <button
          className={`${eDivClasses} justify-start mt-1 mb-1 flex w-full text-body1 font-semibold`}
          onClick={isEditCarrierInfo ? openDialogEditCarrierInfo : openDialog}
        >
          <div>
            <img src={iconChangeKeyName} alt="IconChangeKeyName" />
          </div>
          <p className="ml-3">Edit</p>
        </button>
        <div className={`${hiddenBlock}`}>
          <hr className="border-t-neutral-7 my-1" />
          <button
            className={`${eDivClasses} justify-start mb-1 flex w-full text-body1 font-semibold`}
            onClick={() =>
              handleShowActionsDialog(userInfo?.isBlocked ? TxtAction.UNBLOCK : TxtAction.BLOCK)
            }
          >
            <div>
              <img src={userInfo?.isBlocked ? iconUnlock : iconLock} alt="IconBlockUnblock" />
            </div>
            <p className="ml-3">{userInfo?.isBlocked ? 'Unblock' : 'Block'}</p>
          </button>
        </div>
        <hr className={`border-t-neutral-7 my-1 ${isJustEdit && 'hidden'} `} />
        <button
          className={`${eDivClasses} ${
            isJustEdit && 'hidden'
          } justify-start mb-1 flex w-full text-body1 font-semibold text-indicator-red`}
          onClick={() => handleShowActionsDialog(TxtAction.DELETE)}
        >
          <div>
            <img src={iconTrashActive} alt="IconTrash" />
          </div>
          <p className="ml-3">Delete</p>
        </button>
      </div>
      <EditUserDialog
        modalIsOpen={modalIsOpen}
        handleCloseDialog={() => {
          setIsModelOpen(false)
          handleClose()
        }}
        userInfo={userInfo}
      />
      <EditAccountInfoDialog
        userInfo={userInfo}
        isBusinessInfo={isBusinessInfo}
        modalIsOpen={modalIsOpenEditCarrierInfo}
        handleCloseDialog={() => {
          setIsModelOpenEditCarrierInfo(false)
          handleClose()
        }}
        handleRefetchData={handleRefetchData}
      />
      <UserListDialog
        modalIsOpen={isOpenActionsDialog}
        handleCloseDialog={handleCloseActionsDialog}
        title={contentDialog[txtAction].title}
        content={contentDialog[txtAction].content}
        txtBtn={contentDialog[txtAction].txtBtn}
        handleAction={contentDialog[txtAction].handleAction}
      />
    </div>
  )
}

export default RowEditUser
