import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormInput } from '../../../components/partials/formInput'
import { alphabetPattern, americanPhoneNumberPattern, emailPattern } from '../../../utils/regex'
import { MESSAGES, STATUS } from '../../../constants/messages'
import ButtonBase from '../../../components/partials/button'
import BaseDialog from '../../../components/dialog'
import iconTimes from '../../../assets/images/icon-times.svg'
import { customStyles } from '../../../constants/customStyles'
import { FormInputPhoneNumber } from '../../../components/partials/formInputPhoneNumber'
import { useSession } from '../../../helpers/checkSession'
import { useMutation, useQuery } from '@apollo/client'
import {
  EDIT_DEVELOPER_INFO_BY_ADMIN,
  EDIT_SPONSOR_INFO_BY_ADMIN,
  GET_ALL_ROLE_BY_KIND,
} from '../../../queries/adminPortal'
import { notify } from '../../../components/toastify'
import { BusinessSponsorInfo, DeveloperInfo } from '../components/models'
import DropdownBase from '../../../components/partials/dropDown'

const rules = {
  charsRules: {
    required: MESSAGES.ERROR.E_REQUIRED_FIELD,
    pattern: alphabetPattern,
    maxLength: 200,
  },
  emailRules: {
    required: MESSAGES.ERROR.E_REQUIRED_FIELD,
    pattern: emailPattern,
    maxLength: 200,
  },
}

type Option = {
  id: number | string
  name: string
}

export type EditAccountInfoFormFields = {
  userFirstName: string
  userLastName: string
  email: string
  phoneNumber: string
}

interface PropsAction {
  modalIsOpen?: boolean
  hiddenBlock?: string
  handleEdit?: () => void
  handleCloseDialog?: (val: boolean) => void
  isBusinessInfo?: boolean
  userInfo?: BusinessSponsorInfo | DeveloperInfo | any
  handleRefetchData?: () => void
}

const EditAccountInfoDialog: React.FunctionComponent<PropsAction> = ({
  modalIsOpen,
  handleCloseDialog = () => null,
  userInfo,
  isBusinessInfo,
  handleRefetchData = () => null,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm<EditAccountInfoFormFields>()
  const [level, setLevel] = useState<Option | any>(userInfo?.role)
  const [levelList, setLevelList] = useState<Option[]>([])
  const [editCarrierInfo] = useMutation(
    isBusinessInfo ? EDIT_SPONSOR_INFO_BY_ADMIN : EDIT_DEVELOPER_INFO_BY_ADMIN,
  )

  const { handleErrorSession } = useSession()

  const { data: getAllRoleData, loading: getAllRoleLoading } = useQuery(GET_ALL_ROLE_BY_KIND, {
    fetchPolicy: 'no-cache',
    variables: {
      kind: userInfo?.organization?.kind,
    },
  })

  const onSubmit = handleSubmit(async (data) => {
    const developerVariables = {
      data: {
        firstName: data?.userFirstName.trim(),
        lastName: data?.userLastName.trim(),
        email: data?.email.trim(),
        phoneNumber: data?.phoneNumber.trim(),
      },
      userId: userInfo?.userId,
      roleId: level?.id,
    }
    const sponsorVariables = {
      data: {
        businessSponsorFirstName: data?.userFirstName.trim(),
        businessSponsorLastName: data?.userLastName.trim(),
        businessSponsorEmail: data?.email.trim(),
        businessSponsorPhoneNumber: data?.phoneNumber.trim(),
      },
      businessSponsorId: userInfo?.businessSponsorId,
    }
    try {
      const dataRes = await editCarrierInfo({
        variables: isBusinessInfo ? sponsorVariables : developerVariables,
      })
      if (dataRes) {
        handleRefetchData()
        notify(MESSAGES.SUCCESS.S_UPDATED_CARRIER_INFO, STATUS.SUCCESS)
        handleCloseDialog(false)
        reset()
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_UPDATE_CARRIER_INFO)
    }
  })

  const handleChangeAccountLevel = (val: any) => setLevel(val)

  const handleTrimValues = () => {
    setValue('userFirstName', getValues('userFirstName').trim())
    setValue('userLastName', getValues('userLastName').trim())
    setValue('email', getValues('email').trim())
  }

  const handleSaveChanges = async () => {
    await handleTrimValues()
    await onSubmit()
  }

  const handleCloseEditUserDialog = () => {
    handleCloseDialog(false)
    reset()
  }

  useEffect(() => {
    const levelListTransform = getAllRoleData?.getAllRoleByKind?.map((item: any) => ({
      id: item?.roleId,
      name: item?.name,
    }))
    setLevelList(levelListTransform)
  }, [getAllRoleData])

  useEffect(() => {
    setLevel(userInfo?.role)
    if (isBusinessInfo) {
      setValue('userFirstName', userInfo?.businessSponsorFirstName)
      setValue('userLastName', userInfo?.businessSponsorLastName)
      setValue('email', userInfo?.businessSponsorEmail)
      setValue('phoneNumber', userInfo?.businessSponsorPhoneNumber)
    } else {
      setValue('userFirstName', userInfo?.firstName)
      setValue('userLastName', userInfo?.lastName)
      setValue('email', userInfo?.email)
      setValue('phoneNumber', userInfo?.phoneNumber)
    }
  }, [userInfo])

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={customStyles}>
      <div className="w-[758px] p-1 pr-0">
        <div className="float-right">
          <img
            src={iconTimes}
            alt=""
            className="cursor-pointer"
            onClick={handleCloseEditUserDialog}
          />
        </div>
        <p className="mb-2 text-headline4">Edit account information</p>
        <hr className="mt-3 mb-5 mr-1 border-neutral-5" />
        {isBusinessInfo ? (
          <p className="mb-3 text-headline5A">Business management information</p>
        ) : (
          <p className="mb-3 text-headline5A">Developer information</p>
        )}
        <form onSubmit={handleSaveChanges}>
          <div className="grid grid-cols-2 gap-2">
            <div className="mt-2">
              <p className="mb-2 text-hairline2">First name</p>
              <FormInput<EditAccountInfoFormFields>
                id="userFirstName"
                type="text"
                name="userFirstName"
                placeholder={
                  isBusinessInfo ? 'Enter your first name' : "Enter developer's first name"
                }
                className=""
                inputClassName="w-[356px] mt-1"
                register={register}
                rules={rules.charsRules}
                errors={errors}
              />
            </div>
            <div className="mt-2 ml-2">
              <p className="mb-2 text-hairline2">Last name</p>
              <FormInput<EditAccountInfoFormFields>
                id="userLastName"
                type="text"
                name="userLastName"
                placeholder={
                  isBusinessInfo ? 'Enter your last name' : "Enter developer's last name"
                }
                className=""
                inputClassName="w-[356px] mt-1"
                register={register}
                rules={rules.charsRules}
                errors={errors}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div className="mt-4">
              <p className="mb-2 text-hairline2">Business email</p>
              <FormInput<EditAccountInfoFormFields>
                id="email"
                type="email"
                name="email"
                placeholder={
                  isBusinessInfo
                    ? 'Enter email with your company domain'
                    : "Enter email with developer's company domain"
                }
                className=""
                inputClassName="w-[356px] mt-1"
                register={register}
                rules={rules.emailRules}
                errors={errors}
              />
            </div>
            <div className="mt-4 ml-2">
              <p className="mb-2 text-hairline2">
                Phone number {isBusinessInfo && <span className="text-body3">(Optional)</span>}
              </p>
              <FormInputPhoneNumber<EditAccountInfoFormFields>
                control={control}
                id="phoneNumber"
                type="text"
                name="phoneNumber"
                placeholder={
                  isBusinessInfo ? 'Enter your phone number' : "Enter developer's phone number"
                }
                className=""
                inputClassName="w-[356px] mt-1"
                register={register}
                rules={{
                  required: isBusinessInfo ? false : MESSAGES.ERROR.E_REQUIRED_FIELD,
                  pattern: americanPhoneNumberPattern,
                }}
                errors={errors}
                value={getValues().phoneNumber}
              />
            </div>
            <div className="mt-4">
              <p className="mb-2 text-hairline2">Level of carrier</p>
              {getAllRoleLoading ? null : (
                <div className="w-[356px] mt-1">
                  <DropdownBase
                    options={levelList}
                    handleOptions={handleChangeAccountLevel}
                    placeHolder="Choose account type"
                    isError={false}
                    initialValue={level?.name}
                  />
                </div>
              )}
              {!level && (
                <p className="block mt-1 text-sm text-left text-red-900">
                  {MESSAGES.ERROR.E_REQUIRED_FIELD}
                </p>
              )}
            </div>
          </div>
          <hr className="mt-4 mb-2" />
        </form>
        <div className="flex w-[750px]">
          <ButtonBase
            type="submit"
            className="w-[374px] my-4 bg-primary-1 min-w-min text-center"
            onClick={handleSaveChanges}
            bgDisabled="bg-primary-shade3"
          >
            Save change
          </ButtonBase>
          <ButtonBase
            type="submit"
            className="w-[374px] my-4 ml-3 justify-around border-2 border-primary-1 text-primary-1 bg-white-50 hover:bg-primary-shade4"
            onClick={handleCloseEditUserDialog}
            bgDisabled="bg-primary-shade3"
          >
            Cancel
          </ButtonBase>
        </div>
      </div>
    </BaseDialog>
  )
}

export default EditAccountInfoDialog
