import React, { useState } from 'react'
import Tabs, { TabsType } from '../../components/partials/tabs'
import ActivitiesManagement from '../activitiesManagement'
import DevelopmentManagement from '../developmentManagement'

// Tabs Array
const tabs: TabsType = [
  {
    label: 'Activities management',
    index: 1,
    Component: ActivitiesManagement,
    // roles: [ROLE.Admin, ROLE.Developer]
  },
  {
    label: 'Development management',
    index: 2,
    Component: DevelopmentManagement,
    // roles: [ROLE.Admin, ROLE.Developer]
  },
]

const DeveloperPortal = () => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index)

  return (
    <Tabs
      selectedTab={selectedTab}
      pageName="Developer portal"
      onClick={setSelectedTab}
      tabs={tabs}
    />
  )
}

export default DeveloperPortal
