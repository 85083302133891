export const tableEmailConfig = [
  {
    header: {
      id: 1,
      className: 'w-[45%]',
      label: 'Start Subject',
    },
    row: {
      className: 'w-[45%] min-h-5',
      key: 'startSubject',
    },
  },
  {
    header: {
      id: 2,
      className: 'w-[45%]',
      label: 'Cancel Subject',
    },
    row: {
      key: 'cancelSubject',
      className: 'w-[45%] min-h-5',
    },
  },
  {
    header: {
      id: 3,
      className: 'w-[8%]',
      label: 'Total stage',
    },
    row: {
      key: 'subEmailManagementConfig',
      className: 'w-[8%] min-h-5',
    },
  }
  // {
  //   header: {
  //     id: 3,
  //     key: 'emailTemplateURL',
  //     minW: 'w-[220px] min-w-[64px] md:mr-4',
  //     label: 'Email Template',
  //   },
  //   row: {
  //     key: 'emailTemplateURL',
  //     minW: 'w-[220px] hover:bg-neutral-7 hover:text-neutral-1 hover:h-auto',
  //   },
  // },
  // {
  //   header: {
  //     id: 4,
  //     key: 'textTemplateURL',
  //     minW: 'w-[200px] min-w-[64px] md:mr-4',
  //     label: 'Text Template',
  //   },
  //   row: {
  //     key: 'textTemplateURL',
  //     minW: 'w-[200px] hover:bg-neutral-7 hover:text-neutral-1 hover:h-auto',
  //   },
  // },
  // {
  //   header: {
  //     id: 5,
  //     minW: 'w-[200px] min-w-[64px] md:mr-4',
  //     label: 'Action',
  //   },
  //   row: {
  //     minW: 'w-[200px] hover:bg-neutral-7 hover:text-neutral-1 hover:h-auto',
  //   },
  // },
]
