import React, { useState } from 'react'
import Tabs, { TabsType } from '../../components/partials/tabs'
import SystemConfiguration from '../system-configuration'
import SystemMonitoring from '../system-monitoring'
import UsersManagement from '../users-management'
import EmailSystemManagement from '../email-system-management'

// Tabs Array
const tabs: TabsType = [
  {
    label: 'System monitoring',
    index: 1,
    Component: SystemMonitoring,
  },
  {
    label: 'Users management',
    index: 2,
    Component: UsersManagement,
  },
  {
    label: 'System configuration',
    index: 3,
    Component: SystemConfiguration,
  },
  {
    label: 'Email System',
    index: 4,
    Component: EmailSystemManagement,
  },
]

const AdminPortal = () => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index)

  return (
    <div>
      <Tabs
        selectedTab={selectedTab}
        pageName="Admin portal"
        onClick={setSelectedTab}
        tabs={tabs}
      />
    </div>
  )
}

export default AdminPortal
