import { ApolloProvider } from '@apollo/client'
import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { BrowserRouter } from 'react-router-dom'

import { client } from './network/apolloClient'
import { RouterOutlet } from './routes/routerOutlet'
import { store } from './store'
import './index.css'
import BaseToast from './components/toastify'
import appRoutes from './appRoutes'

const clientId = process.env.REACT_APP_PRODUCTION_CLIENT_ID || ''

const App = () => {
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = 'Propeller - Production'
  }, [])
  return (
    <ApolloProvider client={client}>
      <GoogleOAuthProvider clientId={clientId}>
      <Provider store={store}>
        <BrowserRouter>
          <BaseToast />
          <RouterOutlet routes={appRoutes} />
        </BrowserRouter>
      </Provider>
      </GoogleOAuthProvider>
    </ApolloProvider>
  )
}

export default App
