import React from 'react'
import iconApprove from '../../assets/images/icon-approve.svg'
import iconReject from '../../assets/images/icon-reject.svg'
import classNames from 'classnames'
interface PropsAction {
  handleApprove: () => void
  handleReject: () => void
}

const RowActionNewUserRequest: React.FunctionComponent<PropsAction> = ({
  handleApprove = () => null,
  handleReject = () => null,
}) => {
  const eDivClasses = classNames(
    `flex items-center p-2 hover:bg-neutral-7 hover:rounded-lg pointer-events-auto`,
  )

  return (
    <div className="relative h-auto p-3 border shadow-2xl w-60 border-neutral-7 bg-neutral-8 rounded-xl">
      <div className={`${eDivClasses} justify-start mb-1 flex`} onClick={handleApprove}>
        <div>
          <img src={iconApprove} alt="IconTrashActive" />
        </div>
        <p className="ml-3">Approve</p>
      </div>
      <hr className="my-1 border-t-neutral-7" />
      <div className={`${eDivClasses} justify-start mb-1 flex`} onClick={handleReject}>
        <div>
          <img src={iconReject} alt="IconTrashInActvie" />
        </div>
        <p className="ml-3">Reject</p>
      </div>
    </div>
  )
}

export default RowActionNewUserRequest
