import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormAutocompleInput, { options } from './components/formAutocompleInput'
import GroupAccount from './components/groupPrincipalAccount'
import NoResultFound from '../../components/partials/noResult'
import { OptionType } from './components/dropdown'
import { RootState } from '../../store'
import {
  clearCountSearchPrincipalAccount,
  clearSearchPrincipal,
  clearTakeItemSearchPrincipalAccount,
  clearValueSearchPrincipalAddress,
  clearValueSearchPrincipalName,
  closePrincipalAccountDetail,
  searchPrincipal,
} from '../../redux/actions/adminPortal'
import Principal from './components/principal'
import PrincipalAccount from './components/principalAccount'
import PrincipalAccountDetail from './containers/principalAccountDetail'
import { useMutation } from '@apollo/client'
import { useSession } from '../../helpers/checkSession'
import { SEARCH_PRINCIPAL_ACCOUNT } from '../../queries/adminPortal'
import { MESSAGES } from '../../constants/messages'

const PrincipalAccountManagement = () => {
  const [dropdownValueProps, setDropdownValueProps] = useState<OptionType>(options[0])
  const [isOpenPrincipalDetail, setIsOpenPrincipalDetail] = useState<boolean>(false)
  const [principalAccountDetail, setPrincipalAccountDetail] = useState()
  const [searchPrincipalAccount] = useMutation(SEARCH_PRINCIPAL_ACCOUNT)
  const { handleErrorSession } = useSession()
  const dispatch = useDispatch()

  const handleOpenAndClosePrincipalDetail = (isOpen: boolean) => {
    setIsOpenPrincipalDetail(isOpen)
  }

  const handleSetPrincipalAccountDetail = (data: any) => {
    setPrincipalAccountDetail(data)
  }

  const searchPrincipalState = useSelector(
    (state: RootState) => state?.adminPortalReducer?.searchPrincipal,
  )

  const searchPrincipalNameState = useSelector(
    (state: RootState) => state?.adminPortalReducer?.valuePrincipalName,
  )

  const searchPrincipalAddressState = useSelector(
    (state: RootState) => state?.adminPortalReducer?.valuePrincipalAddress,
  )

  const takeItemSearchPrincipalAccount = useSelector(
    (state: RootState) => state?.adminPortalReducer?.takeItemSearchPrincipalAccount,
  )

  const countSearchPrincipalAccount = useSelector(
    (state: RootState) => state?.adminPortalReducer?.countSearchPrincipalAccount,
  )

  const handleSearchPAcc = async () => {
    const variables = {
      data: {
        principalAccountName: searchPrincipalNameState,
        principalAccountAddressLine1: searchPrincipalAddressState,
        skip: 0,
        take: takeItemSearchPrincipalAccount,
      },
    }
    try {
      const res = await searchPrincipalAccount({
        variables: variables,
      })
      dispatch(searchPrincipal(res.data.searchPrincipalAccountByNameAndAddress))
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_TRY_AGAIN)
    }
  }

  useEffect(() => {
    dispatch(clearSearchPrincipal())
    dispatch(clearValueSearchPrincipalName())
    dispatch(clearValueSearchPrincipalAddress())
    dispatch(closePrincipalAccountDetail())
    dispatch(clearTakeItemSearchPrincipalAccount())
    dispatch(clearCountSearchPrincipalAccount())
  }, [])

  useEffect(() => {
    document.body.scrollTo(0, 0)
    if (!isOpenPrincipalDetail && countSearchPrincipalAccount !== 0) {
      handleSearchPAcc()
    }
  }, [isOpenPrincipalDetail])

  return (
    <div className="px-20 py-10 md:px-10">
      <div className={`${isOpenPrincipalDetail ? 'hidden' : 'block'}`}>
        <GroupAccount />
        <hr className="border-neutral-6 my-10" />
        <FormAutocompleInput setDropdownValueProps={setDropdownValueProps} />
        {Boolean(searchPrincipalState?.count && dropdownValueProps.value === options[1].value) && (
          <Principal />
        )}
        {/* START REGION: Principal Account's list data */}
        {Boolean(searchPrincipalState?.count && dropdownValueProps.value === options[0].value) && (
          <PrincipalAccount
            handleOpenAndClosePrincipalDetail={handleOpenAndClosePrincipalDetail}
            handleSetPrincipalAccountDetail={handleSetPrincipalAccountDetail}
          />
        )}
        {/* END REGION: Principal Account's list data */}
        {searchPrincipalState?.count === 0 && <NoResultFound />}
      </div>
      {isOpenPrincipalDetail && (
        <PrincipalAccountDetail
          handleOpenAndClosePrincipalDetail={handleOpenAndClosePrincipalDetail}
          principalAccountDetailInfo={principalAccountDetail}
          handleSearchPAcc={handleSearchPAcc}
        />
      )}
    </div>
  )
}

export default PrincipalAccountManagement
