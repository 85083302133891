import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@apollo/client'
import { RootState } from '../../store'
import { GET_MY_ACCOUNT } from '../../queries/myAccount'
import Card from './../../components/card'
import Loading from '../../components/loading'
import { useSession } from '../../helpers/checkSession'
import { CODE_TIMEOUT } from '../../constants/statusCode'
import { ROLE } from '../../constants/common'

interface Role {
  Admin: string
  Developer: string
}

const MyAccount = () => {
  const { timeOutSession } = useSession()

  const userInfoState = useSelector((state: RootState) => state.userReducers?.userInfo)
  const { data, loading, error } = useQuery(GET_MY_ACCOUNT, {
    fetchPolicy: 'no-cache',
  })
  const role: Role = {
    Admin: 'Admin',
    Developer: 'Developer',
  }

  useEffect(() => {
    if (error?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT) {
      timeOutSession()
    }
  }, [error])

  if (loading) return <Loading />

  return (
    <div>
      {role[userInfoState?.role?.name as keyof Role] === role.Admin && (
        <div className="pt-10 pb-[120px] pl-20 pr-20 md:px-10">
          <p className="text-headline5A text-neutral-1">My account information</p>
          <Card
            avatar={userInfoState?.avatar}
            firstName={userInfoState?.firstName}
            lastName={userInfoState?.lastName}
            title="Propeller admin"
            hidden="hidden"
            hiddenBlock="hidden"
            isShowEditAction={true}
            userInfo={data?.getMyAccountInfo}
            isMyAccount={true}
          />
        </div>
      )}
      {[ROLE.Developer, ROLE.DeveloperAccountManager, ROLE.AccountManager].includes(
        userInfoState?.role?.name,
      ) && (
        <div className="pt-10 pb-[120px] pl-20 pr-20 md:px-10">
          <p className="text-headline5A text-neutral-1">Business Management information</p>
          <Card
            title="Business management"
            firstName={data?.getMyAccountInfo?.party?.businessSponsor?.businessSponsorFirstName}
            lastName={data?.getMyAccountInfo?.party?.businessSponsor?.businessSponsorLastName}
            businessMail={data?.getMyAccountInfo?.party?.businessSponsor?.businessSponsorEmail}
            phoneNumber={data?.getMyAccountInfo?.party?.businessSponsor?.businessSponsorPhoneNumber}
            hiddenBlock="hidden"
            isShowEditAction={false}
            isMyAccount={true}
          />
          <hr className="w-full h-[1px] bg-neutral-6 mt-[52px] mb-[40px]" />
          <p className="text-headline5A text-neutral-1">Developer information</p>
          <Card
            title="Developer"
            avatar={data?.getMyAccountInfo?.avatar}
            firstName={data?.getMyAccountInfo?.firstName}
            lastName={data?.getMyAccountInfo?.lastName}
            propellerMail={data?.getMyAccountInfo?.gsuiteUsername}
            businessMail={data?.getMyAccountInfo?.email}
            phoneNumber={data?.getMyAccountInfo?.phoneNumber}
            isShowEditAction={false}
            isMyAccount={true}
          />
        </div>
      )}
    </div>
  )
}

export default MyAccount
