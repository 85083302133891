import React from 'react'
import { HandleRequest } from '@apollo/sandbox/src/helpers/postMessageRelayHelpers'
import { ApolloExplorer } from '@apollo/explorer/react'

// get token from local storage
const token = window.localStorage.getItem('accessToken')

const ApiExplorer = () => {
  const handleRequest: HandleRequest = (endpointUrl, options) => {
    return fetch(endpointUrl, {
      ...options,
      headers: {
        ...options.headers,
        authorization_ui: `Bearer ${token}`,
      },
    })
  }

  return (
    <div className="my-8 mx-20">
      <ApolloExplorer
        className="h-[700px]"
        graphRef="GraphForCarrier@current"
        autoInviteOptions={{
          inviteToken: '6e0f2193-92f9-4858-b50a-7125b3229a29',
          accountId: 'will-nguyens-team',
        }}
        persistExplorerState={true}
        handleRequest={handleRequest}
      />
    </div>
  )
}

export default ApiExplorer
