import React, { useEffect, useState } from 'react'
import ButtonBase from '../../../components/partials/button'
import PrincipalAccountInfo from '../components/principalAccountInfo'
import PrincipalAccountDetailTable from '../tables/principalAccountDetail'
import iconArrowRight from '../../../assets/images/right-arrow.svg'
import iconPen from '../../../assets/images/icon-pen-bold.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
  closePrincipalAccountDetail,
  getPrincipalAccountDetail,
  getPrincipalAccountInfoDetail,
} from '../../../redux/actions/adminPortal'
import { RootState } from '../../../store'
import { useMutation } from '@apollo/client'
import { MESSAGES } from '../../../constants/messages'
import {
  GET_PRINCIPAL_ACCOUNT_DETAIL,
  GET_PRINCIPAL_ACCOUNT_INFO,
} from '../../../queries/adminPortal'
import { useSession } from '../../../helpers/checkSession'
import Loading from '../../../components/loading'
import UpdatePrincipalAccount from '../dialog/editPrincipal'

interface Props {
  handleOpenAndClosePrincipalDetail?: (val: boolean) => void
  principalAccountDetailInfo?: any
  handleSearchPAcc?: () => void
}

const PrincipalAccountDetail: React.FC<Props> = ({
  handleOpenAndClosePrincipalDetail = () => null,
  principalAccountDetailInfo,
  handleSearchPAcc = () => null,
}) => {
  const [modalIsOpen, setIsModelOpen] = useState<boolean>(false)
  const [isLoadingDataEdited, setIsLoadingDataEdited] = useState<boolean>(false)
  const { handleErrorSession } = useSession()
  const dispatch = useDispatch()
  const isOpenPrincipalAccountDetail = useSelector(
    (state: RootState) => state?.adminPortalReducer?.isOpenPrincipalAccountDetail,
  )
  const principalAccountDetailInformation = useSelector(
    (state: RootState) => state?.adminPortalReducer?.principalAccountDetailInfo,
  )
  const [
    requestGetPrincipalAccountDetailData,
    { data: principalAccountDetailData, loading: principalAccountDetailLoading },
  ] = useMutation(GET_PRINCIPAL_ACCOUNT_DETAIL, {
    fetchPolicy: 'no-cache',
    variables: {
      data: {
        skip: 0,
        take: 50,
        principalAccountId: principalAccountDetailInfo?.principalAccountId,
      },
    },
  })

  const [
    requestGetPrincipalAccountInfo,
    { data: principalAccountInfoData, loading: principalAccountInfoLoading },
  ] = useMutation(GET_PRINCIPAL_ACCOUNT_INFO, {
    fetchPolicy: 'no-cache',
    variables: {
      data: {
        principalAccountId: principalAccountDetailInfo?.principalAccountId,
      },
    },
  })

  const handleGoBack = () => {
    handleSearchPAcc()
    handleOpenAndClosePrincipalDetail(false)
    dispatch(closePrincipalAccountDetail())
  }

  const handleGetPrincipalDetailData = async () => {
    try {
      const res = await requestGetPrincipalAccountInfo()
      dispatch(getPrincipalAccountInfoDetail(res?.data?.getPrincipalAccount))
      setIsLoadingDataEdited(false)
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_TRY_AGAIN)
    }
    try {
      const res = await requestGetPrincipalAccountDetailData()
      dispatch(getPrincipalAccountDetail(res?.data?.getPrincipalsInPrincipalAccountDetail))
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_TRY_AGAIN)
    }
  }

  useEffect(() => {
    if (isOpenPrincipalAccountDetail) {
      handleGetPrincipalDetailData()
    }
  }, [isOpenPrincipalAccountDetail])

  useEffect(() => {
    if (isLoadingDataEdited) {
      handleGetPrincipalDetailData()
    }
  }, [isLoadingDataEdited])

  if (principalAccountDetailLoading || principalAccountInfoLoading) return <Loading />

  return (
    <div className="mt-[-30px]">
      <div>
        <div className="flex items-center py-3">
          <button className="bg-neutral-7 p-2 rounded-full" onClick={handleGoBack}>
            <img src={iconArrowRight} alt="Icon arrow" />
          </button>
          <span className="pl-8 text-neutral-1 text-headline4 font-semibold">
            {principalAccountDetailInformation?.principalAccountName}
          </span>
        </div>
        <div className="flex justify-between mb-4 mt-8">
          <p className="text-headline5A">Information</p>
          <div className="float-right">
            <ButtonBase
              className="justify-around w-[89px] h-[40px] px-3 border-2 border-neutral-6 text-neutral-4 bg-neutral-8 hover:bg-neutral-7 hover:text-neutral-3"
              onClick={() => setIsModelOpen(true)}
            >
              <div className="flex justify-between">
                <img src={iconPen} alt="iconPen" width={20} height={20} />
                <p>Edit</p>
              </div>
            </ButtonBase>
            <UpdatePrincipalAccount
              modalIsOpen={modalIsOpen}
              principalAccountDetailInfo={principalAccountInfoData?.getPrincipalAccount}
              setIsLoadingDataEdited={setIsLoadingDataEdited}
              handleCloseDialog={() => {
                setIsModelOpen(false)
              }}
            />
          </div>
        </div>
        <PrincipalAccountInfo
          principalAccountDetailInfo={principalAccountInfoData?.getPrincipalAccount}
        />
        <hr className="border-t-neutral-6 mt-6 mb-10" />
        <p className="text-headline5A">Principal list</p>
        <PrincipalAccountDetailTable
          principalAccountData={principalAccountDetailData?.getPrincipalsInPrincipalAccountDetail}
          principalAccountId={principalAccountDetailInfo?.principalAccountId}
          principalAccountLength={principalAccountInfoData?.getPrincipalAccount?.countPrincipal}
        />
      </div>
    </div>
  )
}

export default PrincipalAccountDetail
