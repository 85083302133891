import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ButtonBase from '../../components/partials/button'
import { clearSpamming } from '../../redux/actions/users'
import { RootState } from '../../store'
import RequestAnAccount from './dialog/requestAnAccount'
import UpToTop from '../../components/partials/upToTop'

const QuickStart = () => {
  const accessTokenGg = localStorage.getItem('accessTokenGg')
  const [isOpenRequestAccount, setIsOpenRequestAccount] = useState(false)
  const [isShowUpToTop, setIsShowUpToTop] = useState<boolean>(false)

  const handleCloseRequestAccountDialog = () => {
    setIsOpenRequestAccount(false)
  }

  window.addEventListener('scroll', () => {
    if (window.scrollY > 250) {
      setIsShowUpToTop(true)
    } else {
      setIsShowUpToTop(false)
    }
  })

  // hidden scroll when dialog is opened
  useEffect(() => {
    if (isOpenRequestAccount) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'overlay'
    }
  }, [isOpenRequestAccount])

  // handle clear spamming count when the user requests account fails more than 5 times
  const hours = 0.5
  const dispatch = useDispatch()
  const spammingTime = useSelector((state: RootState) => state.userReducers?.spammingTime)
  if (spammingTime && new Date().getTime() - spammingTime > hours * 60 * 60 * 1000) {
    dispatch(clearSpamming())
  }

  return (
    <div className="mx-20 mt-6 text-body1-med">
      <p className="mb-5 font-bold">Welcome to Propeller’s API’s.</p>
      <p className="mb-5">Firstly if you have any questions please send us an email.</p>
      <p className="mb-5">How to get Access:</p>
      <ul className="ml-6 list-disc list-outside">
        <li className="mb-2">
          <span className="font-bold">
            Step 01: Get approval from your business management to get access to this Developer
            Portal.
          </span>
        </li>
        <div>
          <p className="mb-5">
            To get a Developer Portal login you must be working for a Carrier that Propeller is
            currently working with or working toward that aim.
          </p>
          <p className="mb-5">
            The assumption is that you are a developer working for a Carrier and you need to include
            the following details in your request.
          </p>
          <div className="flex ml-3">
            <div className="w-[3px] h-[3px] border border-black-800 bg-black-800 rounded-full mt-[13px] mr-4" />
            <span>
              The full name , email address and phone number of the “Business Management “ who asked
              you to review this site.
            </span>
          </div>
          <div className="flex ml-3">
            <div className="w-[3px] h-[3px] border border-black-800 bg-black-800 rounded-full mt-[13px] mr-4" />
            <span>Your Full name and cell number where we can send confidential details to:</span>
          </div>
          <p className="mt-4 mb-5 ml-8">
            Our business team will contact your “Business Management” to verify your bonafide’s.
            Once this confirmed, we will send your login details to allow you access to this site.
          </p>
        </div>
        <li className="mb-2">
          <span className="font-bold">Step 02: Receive login information.</span>
        </li>
        <p>We will send you your login information.</p>
        <li className="mt-4 mb-2">
          <span className="font-bold">
            Step 03: Access your API authentication information to access our site.
          </span>
        </li>
        <p>
          You will be able to get your authentication keys from our developer site and everything
          you need to test your integration.
        </p>
        <li className="mt-4 mb-2">
          <span className="font-bold">
            Step 04: Start building your connectivity. Documentation is{' '}
            <Link target={'_blank'} to="/developer-docs" className="underline text-primary-shade2">
              here
            </Link>
          </span>
        </li>
        <p>
          Once you are satisfied your application works you contact us tell us you want to go to
          production
        </p>
        <li className="mt-4 mb-2">
          <span className="font-bold">
            Step 05: When you are ready to move to production you will have to request another set
            of access keys and obtain business approval to access production data.
          </span>
        </li>
        <div>
          <p className="mb-6">
            Production Access: We will contact you and get you to prove to us that the web hook
            solution is working. And your system doesn’t need to poll us. You can use the trigger a
            web hook to send a request and adjust your records that it sends to reflect records in
            the database.
          </p>
          <p className="mb-6">
            In Parallel we will we will contact your Business Management and request they send us an
            email stating that they are authorized to authorize you to get access to our production
            site.
          </p>
          <p className="mb-6">
            Once we have proven you site works and have your business authorization we wil send you
            a new set of credentials to access production data and production URL’s.
          </p>
        </div>
        <li className="mt-4 mb-2">
          <span className="font-bold">Step 06: Connect to production.</span>
        </li>
        <p className="mb-8">Connect to the production site.</p>
      </ul>
      {!accessTokenGg && (
        <ButtonBase
          type="submit"
          className="w-[248px] bg-primary-1 min-w-min text-center hover:bg-primary-shade1"
          onClick={() => setIsOpenRequestAccount(true)}
        >
          Request an account
        </ButtonBase>
      )}
      <RequestAnAccount
        modalIsOpen={isOpenRequestAccount}
        handleCloseDialog={handleCloseRequestAccountDialog}
      />
      <div className="mt-10" />
      {isShowUpToTop && <UpToTop />}
    </div>
  )
}

export default QuickStart
