import React, { useEffect, useRef, useState } from 'react'
import ChevronUp from '../../assets/images/icon-chevron-up.svg'
import ChevronDown from '../../assets/images/icon-chevron-down.svg'
import Loading from '../loading'
import { twMerge } from 'tailwind-merge'

export type Option = {
  id: number | string
  name: string
  key?: string
  description?: string
}

type Props = {
  options: Option[]
  handleOptions?: (option: Option, name?: string, key?: string) => void
  name?: string
  initialValue?: string | number
  placeHolder?: string
  isError?: boolean
  isLoadingGetListAgency?: any
  className?: string
  hasDescription?: boolean
  initialDescription?: string | number
}

const DropdownBase: React.FC<Props> = ({
  options,
  handleOptions = () => null,
  name,
  initialValue = '',
  placeHolder,
  isError,
  isLoadingGetListAgency,
  className,
  hasDescription = false,
  initialDescription = '',
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [currentValueInput, setCurrentValueInput] = useState<Option>({
    id: 0,
    name: placeHolder ? placeHolder : '00',
  })
  const [select, setSelect] = useState<boolean>(false)
  const [initVal, setInitVal] = useState<string | number>(initialValue)

  const btnRef = useRef<HTMLButtonElement>(null)
  useEffect(() => {
    const onHandleClickOutSide = (e: any) => {
      if (btnRef && !btnRef.current?.contains(e.target)) {
        setShowOptions(false)
      }
    }
    document.addEventListener('click', onHandleClickOutSide)
    return () => {
      document.removeEventListener('click', onHandleClickOutSide)
    }
  }, [showOptions])

  const handleChangeVal = (option: Option) => {
    setInitVal('')
    setCurrentValueInput(option)
  }

  /**
   * Handle stop click out side to affect parent element
   * @param event
   */
  const handleStopPropagation = (event: any) => {
    event.stopPropagation()
  }

  return (
    <div className="items-center">
      <div className="relative inline-block text-left w-[100%]">
        <div>
          <button
            type="button"
            className={`inline-flex items-center shadow-sm bg-white text-sm font-medium text-gray-700 w-[100%] h-[52px] rounded-xl justify-between px-4 gap-3
              border-2 ${isError && '!border-indicator-red'} ${
              showOptions ? 'border-neutral-3' : 'border-neutral-6'
            } 
            `}
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
            onClick={() => {
              setShowOptions(!showOptions)
            }}
            ref={btnRef}
          >
            {hasDescription ? (
              <div className="flex justify-between w-full">
                <div>
                  <span
                    className={`h-6 font-montserrat text-base overflow-hidden text-ellipsis whitespace-nowrap ${
                      select || initVal
                        ? 'font-bold text-neutral-3'
                        : 'font-medium text-base text-neutral-5'
                    }`}
                  >
                    {initVal !== '' ? initVal : currentValueInput?.name}
                    {initVal === undefined && placeHolder}
                  </span>
                </div>
                <div>
                  <span className="h-6 text-gray-700 text-body1">
                    {currentValueInput?.description === undefined
                      ? initialDescription
                        ? `${initialDescription} day(s)`
                        : ''
                      : `${currentValueInput?.description} day(s)`}
                  </span>
                </div>
              </div>
            ) : (
              <span
                className={`h-6 font-montserrat text-base overflow-hidden text-ellipsis whitespace-nowrap ${
                  select || initVal
                    ? 'font-bold text-neutral-3'
                    : 'font-medium text-base text-neutral-5'
                }`}
              >
                {initVal !== '' ? initVal : currentValueInput?.name}
                {initVal === undefined && placeHolder}
              </span>
            )}
            {showOptions ? (
              <img src={ChevronUp} alt="Icon-Chevron-Up" />
            ) : (
              <img src={ChevronDown} alt="Icon-Chevron-Down" />
            )}
          </button>
        </div>
        {showOptions && (
          <div
            className="origin-top-right small-scrollbar absolute right-0 top-[54px] focus:outline-none w-[100%] h-188px rounded-xl m-0 p-2 bg-white-50 border border-neutral-7 shadow-1xl z-10"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}
          >
            {isLoadingGetListAgency && <Loading className="relative py-6" height={20} width={20} />}
            <div
              className={twMerge('pr-1 max-h-[336px] overflow-auto small-scrollbar', className)}
              role="none"
            >
              {options &&
                options.map((option) => (
                  <div
                    key={option.id}
                    className="text-gray-700 block px-4 py-2 font-medium text-sm hover:bg-neutral-7 w-[100%] h-10 top-2 left-2 rounded-lg p-2 cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap"
                    role="menuitem"
                    tabIndex={-1}
                    id="menu-item-0"
                    onMouseDown={(event) => {
                      handleOptions(option, name)
                      // setCurrentValueInput(option)
                      handleChangeVal(option)
                      setShowOptions(!showOptions)
                      setSelect(true)
                      handleStopPropagation(event)
                    }}
                  >
                    {hasDescription ? (
                      <div className="flex justify-between">
                        <div>
                          <span className="h-6 text-neutral-3 text-body1">{option.name}</span>
                        </div>
                        <div>
                          <span className="h-6 text-gray-700 text-body1">
                            {option.description} day(s)
                          </span>
                        </div>
                      </div>
                    ) : (
                      <span className="h-6 text-neutral-3 text-body1">{option.name}</span>
                    )}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DropdownBase
