import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Tabs, { TabsType } from '../../../components/partials/tabs'
import ActivitiesManagement from '../../activitiesManagement'
import CarrierInfo from '../components/carrierInfo'
import DevelopmentManagement from '../../developmentManagement'
import AgenciesListByAdmin from '../my-agencies-list/myAgencyByAdmin'
import { LocationState } from '../../../constants/interface'
import { KIND } from '../../../constants/common'

const AdminPortalDetail = () => {
  const { state } = useLocation()
  const locationState: LocationState = state as object
  const Kind = locationState?.kind

  const tabs: TabsType = [
    {
      label: 'Activity management',
      index: 1,
      Component: ActivitiesManagement,
    },
    {
      label: 'Development management',
      index: 2,
      Component: DevelopmentManagement,
    },
    {
      label: 'My Agencies',
      index: 3,
      Component: AgenciesListByAdmin as any,
    },
    {
      label: 'Information',
      index: 4,
      Component: CarrierInfo,
    },
  ]
  const location = useLocation()
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index)
  const [listTabsForAMS, setListTabsForAMS] = useState<any[]>([])
  // Dummy Carrier Data
  const dummyCarrierData = {
    partyId: 'ed1167bc-79f5-46f1-84fd-4f2c0e52749e',
    kind: 'carriers',
    carrierName: 'CMC Global',
  }

  useEffect(() => {
    if (Kind === KIND.CARRIERS) {
      const list = tabs?.filter((item) => item.index !== 3)
      setListTabsForAMS(list)
    }
  }, [])

  return (
    <Tabs
      pageNameParent="Admin portal"
      pageName="System monitoring"
      goBackURL="/admin-portal"
      selectedTab={selectedTab}
      carrier={location.state || dummyCarrierData}
      tabs={Kind === KIND.CARRIERS ? listTabsForAMS : tabs}
      onClick={setSelectedTab}
      needResponsive={true}
    />
  )
}

export default AdminPortalDetail
