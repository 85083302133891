import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useWindowSize } from '../../../../helpers/useWindowSize'
import { GET_AGENT_BY_AMS } from '../../../../queries/developerPortal'
import Loading from '../../../../components/loading'
import { screens } from '../../../../constants/screens'
import AddOrEditAgentKey from '../../dialog/addOrEditAgentKey'

import IconPen from '../../../../assets/images/icon-pen-bold.svg'
import IconMarketingAgent from '../../../../assets/images/icon-marketing-agent.svg'

type Props = {
  agencyId?: string
  agencyName?: string
}

export const ChildrenCollapseItemAgency: React.FunctionComponent<Props> = ({
  agencyId,
  agencyName,
}) => {
  const windowSize = useWindowSize()
  const responsive = Number(windowSize.width) <= screens.md
  const [isPopupAddOrEditAgentKey, setsPopupAddOrEditAgentKey] = useState<boolean>(false)
  const [agentDetail, setAgentDetail] = useState<object | null>(null)

  //Get Agency List
  const {
    data: listAgentByAMS,
    refetch: getAgentListRefetch,
    loading: getAgentListLoading,
  } = useQuery(GET_AGENT_BY_AMS, {
    variables: { agencyId: agencyId },
  })

  const handleRefetchData = async () => {
    await getAgentListRefetch()
    setAgentDetail(null)
  }

  const handleClosePopupAddOrEditAgentKey = () => {
    setsPopupAddOrEditAgentKey(false)
  }

  //Get Agent List
  const [isListAgent, setIsListAgent] = useState<any>([])
  useEffect(() => {
    setIsListAgent(listAgentByAMS?.getAgentByAMS)
  }, [listAgentByAMS])

  return (
    <div
      className="bg-neutral-7-header-footer pl-[52px] pr-4 text-body1-med pt-4 rounded-b-xl border-2
             border-t-0 border-b-neutral-5 border-x-neutral-5 "
    >
      <hr className="mb-3 mr-1 -ml-8 -mt-3 border-[2px] border-t-neutral-5" />
      {getAgentListLoading && <Loading className="relative py-6" height={30} width={30} />}
      <div className="overflow-y-scroll cus-scrollbar max-h-[500px] -mr-2">
        {isListAgent?.length === 0 ? (
          <div className="pb-3 text-center text-body1 text-neutral-4">No data available.</div>
        ) : (
          <div>
            {isListAgent?.map((agent: any) => (
              <>
                <div className="flex justify-between">
                  <div className={`${!responsive && 'flex justify-start'}`}>
                    <div className={`${!responsive && 'flex h-auto pb-2'}`}>
                      <div className="flex">
                        <img src={IconMarketingAgent} alt="" className="h-6 pl-12" />
                        <div className="w-[300px]">
                          <div className="pl-4 font-semibold text-body1 text-neutral-2">
                            {agent?.agent?.agentName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={`${responsive && 'pl-[70px] pt-3'} flex`}>
                        <li className="pl-4 -mt-1 text-neutral-5" />
                        <div className="flex pt-1 text-neutral-4">
                          <div className="pr-2 font-medium -mt-[3px] text-body2">Agent Key:</div>
                          <div className="-mt-[5px] font-semibold text-body1 text-neutral-3 break-all">
                            <div className="overflow-hidden text-ellipsis whitespace-nowrap lg:max-w-[180px] md:!max-w-[400px]">
                              {agent?.agencyAgentId}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${responsive && 'pl-[70px]'} flex`}>
                        <li className="pl-4 -mt-1 text-neutral-5" />
                        <div className="flex pt-1 text-neutral-4">
                          <div className="pr-2 font-medium -mt-[3px] text-body2 ">
                            AMS Agent Key:
                          </div>
                          {agent?.agencyAgentKey === null ? (
                            <div className="whitespace-nowrap -mt-[3px] italic font-medium text-neutral-3 text-body2">
                              Not created
                            </div>
                          ) : (
                            <div className="lg:max-w-[120px] max-w-[120px] md:!max-w-[400px] xl:max-w-[100px] font-semibold -mt-[5px] text-body1 text-neutral-3 overflow-hidden text-ellipsis whitespace-nowrap">
                              {agent?.agencyAgentKey}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={`${responsive && 'pl-[70px]'} flex`}>
                        <li className="pl-4 -mt-1 text-neutral-5" />
                        <div className="flex pt-1 text-neutral-4">
                          <div className="pr-2 font-medium -mt-[3px] text-body2 ">Email:</div>
                          <div className="font-semibold -mt-[5px] text-body1 text-neutral-3 break-all max-h-[200px]">
                            <div className="md:!max-w-[400px] lg:max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                              {agent?.agent?.agentEmail}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="pr-1 border-2 border-none cursor-pointer bg-neutral-7-header-footer hover:bg-neutral-7-header-footer"
                      onClick={(e) => {
                        setAgentDetail(agent)
                        setsPopupAddOrEditAgentKey(true)
                        e.stopPropagation()
                      }}
                    >
                      <img src={IconPen} alt="" className="" />
                    </div>
                  </div>
                </div>
                <hr className="my-3 ml-12 mr-[6px] border-t-neutral-5 last:border-0" />
              </>
            ))}
          </div>
        )}
      </div>
      <AddOrEditAgentKey
        modalIsOpen={isPopupAddOrEditAgentKey}
        agentInfo={agentDetail}
        handleCloseDialog={handleClosePopupAddOrEditAgentKey}
        agencyName={agencyName}
        handleRefetchData={handleRefetchData}
      ></AddOrEditAgentKey>
    </div>
  )
}

export default ChildrenCollapseItemAgency
