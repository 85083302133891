import React, { FC } from 'react'

import ChildrenCollapseItemAgency from '../../pages/developer-portal/my-agencies-list/component/childrenCollapseItemAgency'
import { ChildrenCollapseItemAgencyByAdmin } from '../../pages/admin-portal/my-agencies-list/component/childrenCollapseItemAgencyByAdmin'

import iconDownArrow from '../../assets/images/icon-down-arrow.svg'
import iconUpArrow from '../../assets/images/icon-up-arrow.svg'

type Props = {
  id?: number
  handleOpenCollapse?: (value: number) => void
  children?: React.ReactNode
  image?: any
  title?: string
  information?: any
  addAgencyKey?: any
  listCollapse?: number[]
  isSelectedCollapse?: boolean
  agencyId?: string
  agencyName?: string
  isAgencyByAdmin?: boolean
}

export const CollapseItemForAgency: FC<Props> = ({
  id,
  handleOpenCollapse = () => null,
  children,
  image,
  title,
  information,
  addAgencyKey,
  listCollapse = [],
  isSelectedCollapse,
  agencyId,
  agencyName,
  isAgencyByAdmin,
}) => {
  return (
    <div>
      <div className="my-5">
        <div
          className={`flex justify-between p-2 py-3 font-bold cursor-pointer bg-neutral-7-header-footer rounded-xl border-2 border-neutral-7-header-footer hover:border-neutral-6
          ${
            isSelectedCollapse &&
            'border-x-neutral-5 border-t-neutral-5 border-b-0 rounded-b-none hover:border-x-neutral-5 hover:border-t-neutral-5 rounded-t-xl'
          }
          `}
          onClick={() => handleOpenCollapse(id as number)}
        >
          <div className="flex">
            <img
              src={listCollapse.includes(id as number) ? iconUpArrow : iconDownArrow}
              alt=""
              className="mx-2"
            />
            <p className="ml-3 mr-[14px] mt-[2px]">{image}</p>
            <p className="break-all md:w-[180px] lg:max-w-[240px]">
              <p className="ml-1 overflow-hidden text-headline5 text-ellipsis whitespace-nowrap">
                {title}
              </p>
            </p>
            <p>{information}</p>
          </div>
          <div className="">{addAgencyKey}</div>
        </div>
        {isAgencyByAdmin ? (
          <>{isSelectedCollapse && <ChildrenCollapseItemAgencyByAdmin agencyId={agencyId} />}</>
        ) : (
          <>
            {isSelectedCollapse && (
              <ChildrenCollapseItemAgency agencyId={agencyId} agencyName={agencyName} />
            )}
          </>
        )}
        {listCollapse.includes(id as number) && children}
      </div>
    </div>
  )
}

export default CollapseItemForAgency
