/* eslint-disable react/jsx-key */
import { useQuery } from '@apollo/client'
import { isArray, isBoolean } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import IconHoverCopy from '../../../../assets/images/icon-hover-copy.svg'
import BaseDialog from '../../../../components/dialog'
import ButtonBase from '../../../../components/partials/button'
import { notify } from '../../../../components/toastify'
import { STATUS } from '../../../../constants/messages'
import { isValidHttpUrl } from '../../../../helpers/functionUtils'
import { GET_EMAIL_SUBJECT_BY_ID } from '../../../../queries/adminPortal'

interface PropsAction {
  modalIsOpen?: boolean
  hiddenBlock?: string
  handleCloseDialog?: (val: boolean) => void
  emailManagementConfigId: any
}

interface ILabelValue {
  label: string
  value: any
}

interface ITDayConfig {
  configId: string
  key: string
  value: string
}

interface ISubjectEmailConfig {
  subEmailManagementConfigId: string
  tDayConfig: ITDayConfig
  action: string
  step: number
  emailTemplateURL: string
  textTemplateURL: string
  sendingEmailSubject: string
  createdAt: string
  updatedAt: string
}

interface IEmailConfigSubject {
  emailManagementConfigId: string
  startSubject: string
  cancelSubject: string
  subEmailManagementConfig: ISubjectEmailConfig[]
  createdAt: string
  updatedAt: string
}

interface ITest {
  getEmailManagementConfigById: IEmailConfigSubject
}

const styleDetailJobItem = {
  content: {
    width: '90%',
    maxWidth: '800px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '16px',
    boxShadow: '8px 11px 21px 0px rgba(148,144,144,0.75)',
    background: '#fdfdfd',
  },
}

const camelCaseToWords = (s: string): string => {
  if (s === 'textTemplateURL') {
    return 'Text Template URL'
  } else if (s === 'emailTemplateURL') {
    return 'Email Template URL'
  }
  const result = s.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

const DetailSubjectItemDialog: React.FunctionComponent<PropsAction> = ({
  emailManagementConfigId,
  modalIsOpen,
  handleCloseDialog = () => null,
}) => {
  const [subjectEmailInformation, SetSubjectEmailInformation] = useState<ILabelValue[]>([])
  const [subjectDetailItemData, setSubjectDetailItemData] = useState<IEmailConfigSubject>()
  const {
    loading,
    error,
    data: subjectDetailItem,
  } = useQuery<ITest>(GET_EMAIL_SUBJECT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: {
      emailManagementConfigId,
    },
  })

  useEffect(() => {
    if (subjectDetailItem) {
      const dataDetail = subjectDetailItem.getEmailManagementConfigById
      const inform: ILabelValue[] = []
      const stageInform: ILabelValue[][] = []

      for (const [key, value] of Object.entries(dataDetail)) {
        if (!value || key === 'emailManagementConfigId') continue
        if (key === 'createdAt' || key === 'updatedAt') {
          inform.push({ label: camelCaseToWords(key), value: moment(value).format('YYYY-MM-DD') })
          continue
        }
        if (key === 'subEmailManagementConfig' && isArray(value) && value.length > 0) {
          for (const stage of dataDetail.subEmailManagementConfig) {
            const childStageInform: ILabelValue[] = []
            for (const [stageKey, stageValue] of Object.entries(stage)) {
              if (stageKey === 'subEmailManagementConfigId') continue
              if (stageKey === 'tDayConfig') {
                childStageInform.push({
                  label: stage.tDayConfig.key,
                  value: stage.tDayConfig.value,
                })
              } else {
                childStageInform.push({ label: camelCaseToWords(stageKey), value: stageValue })
              }
            }
            stageInform.push(childStageInform)
          }
        } else {
          inform.push({ label: camelCaseToWords(key), value })
        }
      }
      SetSubjectEmailInformation(inform)
      setSubjectDetailItemData(dataDetail)
    }
  }, [subjectDetailItem])

  if (loading) return <div></div>
  if (error) return <div></div>

  const classInfoKey = 'text-neutral-3 text-body2 font-semibold leading-6 min-w-[160px]'
  const classInfoVal = 'text-neutral-3 text-body1 min-w-[460px] break-words'

  const onHandleCopy = (value: string, textNotification: string) => {
    navigator.clipboard.writeText(value)
    notify(textNotification, STATUS.INFO)
  }

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={styleDetailJobItem}>
      <div className="w-[758px] p-1 pr-0">
        <p className="mb-2 text-headline4">Detail Email Subject Configuration</p>
        <hr className="mt-3 mb-5 mr-1 border-neutral-5" />
        <div className="flex-row h-[200px] overflow-y-scroll">
          {subjectEmailInformation.map((item) => (
            <div className="flex mb-4">
              <div className="w-1/6">
                <p className={classInfoKey}>{item.label}</p>
                {/* <p>{item.label}</p> */}
              </div>
              <div className="w-5/6">
                {isBoolean(item.value) ? (
                  <p className={classInfoVal}>{item.value.toString()}</p>
                ) : isValidHttpUrl(item.value) ? (
                  <a href={item.value} className={classInfoVal}>
                    {item.value}
                  </a>
                ) : (
                  <p className={classInfoVal}>{item.value}</p>
                )}
              </div>
            </div>
          ))}
        </div>
        <p className="mb-2 mt-3 text-headline4">Stage Detail</p>
        <hr className="mt-3 mb-5 mr-1 border-neutral-5" />
        {subjectDetailItemData?.subEmailManagementConfig &&
        subjectDetailItemData.subEmailManagementConfig.length < 1 ? (
          <p className="py-6 text-center text-body1 text-neutral-4">No data available.</p>
        ) : (
          <div className={`h-[250px] overflow-y-scroll`}>
            <table className="overflow-scroll text-left">
              <thead>
                <tr>
                  <th className="px-4 pb-2 whitespace-nowrap">Step</th>
                  <th className="px-4 pb-2 whitespace-nowrap">Action</th>
                  <th className="px-4 pb-2 whitespace-nowrap">Delay Day(s)</th>
                  <th className="px-4 pb-2 whitespace-nowrap">Sending Email Subject</th>
                  <th className="px-4 pb-2 whitespace-nowrap">Email Template URL</th>
                  <th className="px-4 pb-2 whitespace-nowrap">Text Template URL</th>
                </tr>
              </thead>
              <tbody>
                {subjectDetailItemData?.subEmailManagementConfig.map((stage) => (
                  <tr>
                    <td className="px-4 py-1 whitespace-nowrap">{stage.step}</td>
                    <td className="px-4 py-1 whitespace-nowrap">{stage.action}</td>
                    <td className="px-4 py-1 whitespace-nowrap">{stage.tDayConfig.value}</td>
                    {/* <td className="px-4 py-1 whitespace-nowrap">{stage.sendingEmailSubject}</td> */}
                    <td className="px-4 py-1 whitespace-nowrap max-w-[350px]">
                      {stage.sendingEmailSubject ? (
                        <div className="flex flex-row justify-between">
                          <p className="overflow-hidden text-ellipsis">
                            {stage.sendingEmailSubject ?? '-'}
                          </p>
                          <button
                            className="h-[27px]"
                            title="Copy this Sending Email Subject"
                            onClick={() =>
                              onHandleCopy(
                                stage.sendingEmailSubject,
                                'Copied Sending Email Subject',
                              )
                            }
                          >
                            <img
                              src={IconHoverCopy}
                              alt="Icon-Hover-Copy"
                              className="h-[27px] w-[27px] max-w-[27px]"
                            />
                          </button>
                        </div>
                      ) : (
                        <p className="text-center text-body1 text-neutral-4 italic">No data</p>
                      )}
                    </td>
                    <td className="px-4 py-1 whitespace-nowrap max-w-[350px]">
                      {stage.emailTemplateURL ? (
                        <div className="flex flex-row justify-between">
                          <p className="overflow-hidden text-ellipsis">
                            {stage.emailTemplateURL ?? '-'}
                          </p>
                          <button
                            className="h-[27px]"
                            title="Copy this Email Template URL"
                            onClick={() =>
                              onHandleCopy(stage.emailTemplateURL, 'Copied Email Template URL')
                            }
                          >
                            <img
                              src={IconHoverCopy}
                              alt="Icon-Hover-Copy"
                              className="h-[27px] w-[27px] max-w-[27px]"
                            />
                          </button>
                        </div>
                      ) : (
                        <p className="text-center text-body1 text-neutral-4 italic">No data</p>
                      )}
                    </td>
                    <td className="px-4 py-1 whitespace-nowrap max-w-[350px]">
                      {stage.textTemplateURL ? (
                        <div className="flex flex-row">
                          <p className="overflow-hidden text-ellipsis">
                            {stage.textTemplateURL ?? '-'}
                          </p>
                          <button
                            className="h-[27px]"
                            title="Copy this Text Template URL"
                            onClick={() =>
                              onHandleCopy(stage.textTemplateURL, 'Copied Text Template URL')
                            }
                          >
                            <img
                              src={IconHoverCopy}
                              alt="Icon-Hover-Copy"
                              className="h-[27px] w-[27px] max-w-[27px]"
                            />
                          </button>
                        </div>
                      ) : (
                        <p className="text-center text-body1 text-neutral-4  italic">No data</p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <hr className="mt-4 mb-2" />
        <ButtonBase
          type="submit"
          className="w-[150px] my-4 ml-3 justify-around border-2 border-primary-1 text-primary-1 bg-white-50 hover:bg-primary-shade4"
          onClick={() => {
            handleCloseDialog(false)
          }}
          bgDisabled="bg-primary-shade3"
        >
          Close
        </ButtonBase>
      </div>
    </BaseDialog>
  )
}

export default DetailSubjectItemDialog
