import React, { useEffect, useRef, useState } from 'react'

import InputBase from '../../../../../components/partials/inputBase'
import IconClear from '../../../../../assets/images/ic-clear-input.svg'

import { useDispatch } from 'react-redux'
import { clearSearchPrincipal } from '../../../../../redux/actions/adminPortal'
import { InputValueType } from '../../../../principal-account-management/components/formAutocompleInput'

interface Props {
  classInput?: string
  setIsFocus?: (focus: boolean) => void
  setIsDisable?: (focus: boolean) => void
  setIsValueInputEmp?: (focus: boolean) => void
  setInputValue?: (value: string | InputValueType) => void
  placehoderText?: string
  handleSearch?: (val: string) => void
  handleClearPAccTo?: () => void
  isHandleSearch?: boolean
}

const SearchInput: React.FC<Props> = ({
  classInput = '',
  setIsFocus = () => false,
  setIsDisable = () => false,
  setIsValueInputEmp = () => false,
  placehoderText = 'Search',
  handleSearch = () => null,
  isHandleSearch,
}) => {
  const [valueInputText, setValueInputText] = useState<any>('')
  const [isOpenPlaceholder, setIsOpenPlaceholder] = useState(false)
  const [isFocusSearchInput, setIsFocusSearchInput] = useState<boolean>(false)
  const inputSearchRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()

  const handleChangeInput = (value: string) => {
    setValueInputText(value)
    setIsValueInputEmp(true)
    setIsDisable(false)
    // handleSearch(value)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(valueInputText)
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [valueInputText])

  const handleFocusSearchInput = () => {
    setIsFocus(true)
    setIsFocusSearchInput(true)
  }
  const handleBlurInput = () => {
    setIsFocus(false)
  }

  const resetValueSearchInput = () => {
    setValueInputText('')
    setIsFocusSearchInput(false)
    setIsDisable(false)
    isHandleSearch && dispatch(clearSearchPrincipal())
  }


  const openInput = (event: any) => {
    event.stopPropagation()
    setIsOpenPlaceholder(true)
    setIsFocus(true)
  }

  return (
    <div className="relative w-full flex items-center">
      <div ref={inputSearchRef} className="flex w-full h-full" onClick={openInput}>
        {!isOpenPlaceholder && !valueInputText && (
          <div className="flex items-baseline ml-4 py-3 text-neutral-5 text-body1-med h-full whitespace-nowrap">
            {placehoderText}
          </div>
        )}
        {isOpenPlaceholder === true && (
          <InputBase
            autoFocus={true}
            handleChange={handleChangeInput}
            value={valueInputText}
            className={classInput}
            onFocus={handleFocusSearchInput}
            onBlur={handleBlurInput}
            placeholder={placehoderText}
          />
        )}
      </div>
      {valueInputText && (
        <button
          onClick={resetValueSearchInput}
          className="absolute top-1/2 -translate-y-1/2 bg-neutral-8 right-0 p-3 pl-2 h-full rounded-full"
        >
          <img src={IconClear} alt="icon-clear" width={24} height={24} />
        </button>
      )}
    </div>
  )
}

export default SearchInput
