import { gql } from '@apollo/client'

export const GET_MY_ACCOUNT = gql`
  query GetMyAccountInfo {
    getMyAccountInfo {
      firstName
      lastName
      party {
        businessSponsor {
          businessSponsorPhoneNumber
          businessSponsorEmail
          businessSponsorFirstName
          businessSponsorLastName
          kind
          partyId
          businessSponsorId
        }
      }
      kind
      phoneNumber
      email
      avatar
      gsuiteUsername
      gid
    }
  }
`

export const EDIT_MY_ACCOUNT = gql`
  mutation ModifyAdminInfo($data: ModifyAdminProfileInput!) {
    modifyAdminInfo(data: $data) {
      firstName
      lastName
    }
  }
`
