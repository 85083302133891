import React from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'

import ButtonBase from '../../../components/partials/button'
import BaseDialog from '../../../components/dialog'
import iconTimes from '../../../assets/images/icon-times.svg'
import { FormInput } from '../../../components/partials/formInput'
import { FormInputPhoneNumber } from '../../../components/partials/formInputPhoneNumber'
import {
  emailPattern,
  americanPhoneNumberPattern,
  cityPattern,
  statePattern,
  zipcodePattern,
  addressPattern,
  namePattern,
  contactNamePattern,
} from '../../../utils/regex'
import { MESSAGES, STATUS } from '../../../constants/messages'
import { notify } from '../../../components/toastify'
import { CREATE_AND_GROUP_PRINCIPAL_TO_PRINCIPAL_ACCOUNT } from '../../../queries/adminPortal'
import { useSession } from '../../../helpers/checkSession'
import { customStyles } from '../../../constants/customStyles'

export type AddGroupFormFields = {
  principalId: string
  principalAccountName: string
  principalAccountBusinessEmail: string
  principalAccountContactName: string
  principalAccountPhoneNumber: number
  principalAccountAddressLine1: string
  principalAccountAddressLine2: string
  principalAccountCity: string
  principalAccountState: string
  principalAccountZipcode: number
}

interface PropsAction {
  modalIsOpen?: boolean
  hiddenBlock?: string
  handleEdit?: () => void
  handleCloseDialog?: (val: boolean) => void
  isValueInputPrincipalSelected?: { idPrincipal: string; namePrincipal: string }
}

const AddPrincipalAccount: React.FunctionComponent<PropsAction> = ({
  modalIsOpen,
  isValueInputPrincipalSelected = { idPrincipal: '', namePrincipal: '' },
  handleCloseDialog = () => null,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm<AddGroupFormFields>()
  const { handleErrorSession } = useSession()
  const [createGroupPrincipalToPrincipalAccount] = useMutation(
    CREATE_AND_GROUP_PRINCIPAL_TO_PRINCIPAL_ACCOUNT,
  )

  const onSubmit = handleSubmit(async (data) => {
    const variables = {
      data: {
        principalAccountName: data?.principalAccountName.trim(),
        principalAccountBusinessEmail: data?.principalAccountBusinessEmail.trim(),
        principalAccountContactName: data?.principalAccountContactName.trim(),
        principalAccountAddressLine1: data?.principalAccountAddressLine1.trim(),
        principalAccountAddressLine2: data?.principalAccountAddressLine2.trim(),
        principalAccountCity: data?.principalAccountCity.trim(),
        principalAccountState: data?.principalAccountState.trim(),
        principalAccountZipcode: data?.principalAccountZipcode,
        principalAccountPhoneNumber: data?.principalAccountPhoneNumber,
      },
      principalId: isValueInputPrincipalSelected.idPrincipal,
    }
    try {
      const dataRes = await createGroupPrincipalToPrincipalAccount({ variables: variables })
      if (dataRes) {
        notify(MESSAGES.SUCCESS.S_CREATED_GROUP_P_TO_PACC, STATUS.SUCCESS)
        handleCloseDialog(false)
        reset()
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_CREATED_GROUP_P_TO_PACC)
    }
  })

  const handleTrimValues = () => {
    setValue('principalAccountName', getValues('principalAccountName').trim())
    setValue('principalAccountBusinessEmail', getValues('principalAccountBusinessEmail').trim())
    setValue('principalAccountContactName', getValues('principalAccountContactName').trim())
    setValue('principalAccountAddressLine1', getValues('principalAccountAddressLine1').trim())
    setValue('principalAccountAddressLine2', getValues('principalAccountAddressLine2').trim())
    setValue('principalAccountCity', getValues('principalAccountCity').trim())
    setValue('principalAccountState', getValues('principalAccountState').trim())
  }

  const handleSave = async () => {
    await handleTrimValues()
    await onSubmit()
  }

  const handleCloseAddGroupDialog = () => {
    handleCloseDialog(false)
    reset()
  }

  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={customStyles}>
      <div className="w-[658px] h-[825px] p-2.5 pt-0">
        <div className="flex justify-between">
          <div />
          <img
            src={iconTimes}
            alt=""
            className="cursor-pointer mr-[-12px]"
            onClick={handleCloseAddGroupDialog}
          />
        </div>
        <p className="text-headline4 mb-2 mt-[-16px]">
          Group a Principal to a new Principal Account
        </p>
        <hr className="border-t-neutral-5 mt-4" />
        <div className="overflow-y-scroll cus-scrollbar mr-[-23px] h-[686px]">
          <p className="text-body1 mb-2 mt-6">
            Do you want to move this
            <span className="font-semibold"> {isValueInputPrincipalSelected.namePrincipal}</span> to
            a new Principal Account which you create now?
          </p>
          <p className="text-body2 mb-2">
            Please note that the Principal shall be removed from its current P.Account.
          </p>
          <form onSubmit={handleSave}>
            <p className="text-headline5A mb-2 mt-6">Principal Account information</p>
            <div className="grid grid-cols-1 gap-2">
              <div className="mt-2">
                <p className="text-hairline2 mb-1 mt-1">
                  Name<span className="text-indicator-red">&nbsp;*</span>
                </p>
                <FormInput<AddGroupFormFields>
                  id="principalAccountName"
                  type="text"
                  name="principalAccountName"
                  label="Name"
                  placeholder="Enter principal account name"
                  className="mb-1"
                  inputClassName="w-[636px]"
                  register={register}
                  rules={{
                    required: MESSAGES.ERROR.E_REQUIRED_FIELD,
                    pattern: namePattern,
                    maxLength: 200,
                  }}
                  errors={errors}
                />
              </div>
              <div className="mt-2">
                <p className="text-hairline2 mb-2">Business email</p>
                <FormInput<AddGroupFormFields>
                  id="principalAccountBusinessEmail"
                  type="email"
                  name="principalAccountBusinessEmail"
                  label="Business Email"
                  placeholder="Enter email with your company domain"
                  className="mb-1"
                  inputClassName="w-[636px]"
                  register={register}
                  rules={{
                    pattern: emailPattern,
                    maxLength: 200,
                  }}
                  errors={errors}
                />
              </div>
              <div className="mt-2">
                <p className="text-hairline2 mb-2">Contact name</p>
                <FormInput<AddGroupFormFields>
                  id="principalAccountContactName"
                  type="text"
                  name="principalAccountContactName"
                  label="Contract Name"
                  placeholder="Enter your contact name"
                  className="mb-2"
                  inputClassName="w-[636px]"
                  register={register}
                  rules={{
                    pattern: contactNamePattern,
                    maxLength: 200,
                  }}
                  errors={errors}
                />
              </div>
              <div className="mt-2">
                <p className="text-hairline2 mb-2">Phone number</p>
                <FormInputPhoneNumber<AddGroupFormFields>
                  control={control}
                  id="principalAccountPhoneNumber"
                  type="text"
                  name="principalAccountPhoneNumber"
                  label="Phone Number"
                  placeholder="Enter your phone number"
                  className="mb-2"
                  inputClassName="w-[636px]"
                  register={register}
                  rules={{
                    pattern: americanPhoneNumberPattern,
                  }}
                  errors={errors}
                />
              </div>
              <div className="mt-2">
                <p className="text-hairline2 mb-2">Address line 1</p>
                <FormInput<AddGroupFormFields>
                  id="principalAccountAddressLine1"
                  type="text"
                  name="principalAccountAddressLine1"
                  label="Address Line 1"
                  placeholder="Enter your address"
                  className="mb-2"
                  inputClassName="w-[636px]"
                  register={register}
                  rules={{
                    pattern: addressPattern,
                    maxLength: 500,
                  }}
                  errors={errors}
                />
              </div>
              <div className="mt-2">
                <p className="text-hairline2 mb-2">Address line 2</p>
                <FormInput<AddGroupFormFields>
                  id="principalAccountAddressLine2"
                  type="text"
                  name="principalAccountAddressLine2"
                  label="Address Line 2"
                  placeholder="Enter your address"
                  className="mb-2"
                  inputClassName="w-[636px]"
                  register={register}
                  rules={{
                    pattern: addressPattern,
                    maxLength: 500,
                  }}
                  errors={errors}
                />
              </div>
              <div className="grid grid-cols-3 mt-1 mb-4">
                <div>
                  <p className="text-hairline2 mb-2">City</p>
                  <FormInput<AddGroupFormFields>
                    id="principalAccountCity"
                    type="text"
                    name="principalAccountCity"
                    label="City"
                    placeholder="Enter your city"
                    className="mb-2"
                    inputClassName="w-[199.67px]"
                    register={register}
                    rules={{
                      pattern: cityPattern,
                      maxLength: 200,
                    }}
                    errors={errors}
                  />
                </div>
                <div>
                  <p className="text-hairline2 mb-2">State</p>
                  <FormInput<AddGroupFormFields>
                    id="principalAccountState"
                    type="text"
                    name="principalAccountState"
                    label="State"
                    placeholder="Enter your state"
                    className="mb-2"
                    inputClassName="w-[199.67px]"
                    register={register}
                    rules={{
                      pattern: statePattern,
                      maxLength: 200,
                    }}
                    errors={errors}
                  />
                </div>
                <div>
                  <p className="text-hairline2 mb-2">Zipcode</p>
                  <FormInputPhoneNumber<AddGroupFormFields>
                    control={control}
                    id="principalAccountZipcode"
                    type="text"
                    name="principalAccountZipcode"
                    label="Zipcode"
                    placeholder="XXXXX"
                    className="mb-2"
                    inputClassName="w-[199.67px]"
                    register={register}
                    rules={{
                      pattern: zipcodePattern,
                    }}
                    errors={errors}
                    maxLength={5}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <hr className="border-t-neutral-6 mb-2" />
        <div className="flex w-[636px] pb-0">
          <ButtonBase
            type="submit"
            className="w-[312px] mt-4 bg-primary-1 min-w-min text-center"
            onClick={handleSave}
            bgDisabled="bg-primary-shade3"
          >
            Add and move
          </ButtonBase>
          <ButtonBase
            type="submit"
            className="w-[312px] mt-4 ml-3 justify-around border-2 border-primary-1 text-primary-1 bg-neutral-8 hover:bg-primary-shade4"
            onClick={handleCloseAddGroupDialog}
            bgDisabled="bg-primary-shade3"
          >
            Cancel
          </ButtonBase>
        </div>
      </div>
    </BaseDialog>
  )
}

export default AddPrincipalAccount
