export const MESSAGES = {
  SUCCESS: {
    S_UPDATED_URL: 'The URL has been updated!',
    S_UPDATED_LAST_READ_RECORD: 'The last read record confirmed has been updated!',
    S_KEY_GENERATED: 'An access key has been generated!',
    S_DELETE_API_KEY: 'The access key has been deleted!',
    S_CHANGE_STATUS_API_KEY_ACTIVATE: 'The access key has been activated!',
    S_CHANGE_STATUS_API_KEY_INACTIVATE: 'The access key has been deactivated!',
    S_CREATED_ACCOUNT:
      'Your request is sent to Propeller. We will verify with your business management and get back to you ASAP.',
    S_UPDATED_WEBHOOK_SETTING: 'These changes have been updated.',
    S_PUSHED_WEBHOOK: 'The webhook URL received the message pushed successfully!',
    S_PASSWORD_UPDATE_WEBHOOK: 'Your username and password have been set!',
    S_REQUEST_ACC_TO_PRODUCTION:
      'Your request is sent to Propeller. We will verify with your business management and get back to you ASAP.',
    S_UPDATED_CARRIER: 'The carrier has been updated!',
    S_UPDATED_KEY_NAME: 'The key name has been updated!',
    S_UPDATED_CARRIER_INFO: 'The carrier information has been updated!',
    S_DELETE_USER: 'The user has been deleted.',
    S_BLOCK_USER: 'The user has been blocked.',
    S_UNBLOCK_USER: 'The user has been unblocked.',
    S_APPROVED_USER: 'The user has been approved to access the website.',
    S_REJECTED_USER: 'The user has been rejected to access the website.',
    S_UPDATED_MY_ACCOUNT: 'My information has been updated!',
    S_CREATED_GROUP_P_TO_PACC: 'The Principal is moved to the new Principal Account.',
    S_CREATED_GROUP_PACC_TO_PACC: 'The Principal Account have been grouped successfully!',
    S_CREATED_GROUP_PRINCIPAL_TO_PACC:
      'The Principal Account and Principal have been grouped successfully!',
    S_EDIT_PRINCIPAL_ACCOUNT: 'Your changes have been saved.',
    S_CREATED_CLIENT_CREDENTIAL: 'The Auth0 credential has been created successfully.',
    S_DELETE_CLIENT_CREDENTIAL:
      'The security credential has been deleted successfully. Please add the new one.',
    S_WEBHOOK_SECURITY: 'The new webhook security option has been set up.',
    S_SET_ONE_HEADER_CONFIG: 'The header has been added (one header).',
    S_SET_TWO_HEADER_CONFIG: 'The headers have been added (two headers).',
    S_ADD_AGENCY_KEY: 'The agency key has been added successfully!',
    S_UPDATE_AGENCY_KEY: 'The agency key has been updated successfully!',
    S_ADD_AGENT_KEY: 'The agent key has been added successfully!',
    S_UPDATE_AGENT_KEY: 'The agent key has been updated successfully!',
    S_ADD_AMS_TO_THE_SYSTEM: 'This AMS has been added to the system successfully!',
    S_SUBJECT_UPDATE_SETTING: 'The subject update successfully',
    S_GENERAL_SUCCESS: 'The action update successfully',
    S_CHANGE_STATUS_CONFIGURATION_ACTIVATE: 'The configuration has been activated!',
    S_CHANGE_STATUS_CONFIGURATION_INACTIVATE: 'The configuration has been deactivated!',
    S_CREATE_NEW_CONFIGURATION: 'The configuration has been created!',
    S_CHANGE_CONFIGURATION: 'The configuration has been changed!',
    S_DELETE_CONFIGURATION: 'The configuration has been deleted!',
    S_SET_AUTO_WEBHOOK: 'Set auto sending webhook successfully!',
    S_SET_MANUAL_WEBHOOK: 'Stop auto sending webhook successfully!',
    S_STOP_SENDING_EMAIL_JOB: 'Stop sending email job successfully',
    S_RESTART_SENDING_EMAIL_JOB: 'Restart sending email job successfully',
  },
  WARNING: {
    W_CANNOT_UPDATE_LAST_RECORD: 'Can not update. The latest record must be more than 0.',
    W_RECORD_CONFIRM_MORE_THAN_LAST_RECORD:
      'Cannot update! The last read record confirmed must be less than or equal to the latest record.',
    W_TIMEOUT_SESSION: 'Your session has reached timeout. Please log in again.',
    W_MISSING_INFORMATION_ADD_SUBJECT: 'Missing information previous',
    W_OUT_OF_DATE_CONFIG: 'Out of day delays setting option, please add setting for change previous config',
  },
  ERROR: {
    E_SHORTEN_KEY_NAME: 'Please shorten the key name. The maximum number of characters is 50.',
    E_KEY_NAME_EXISTS: 'This key name already exists. Please choose another name.',
    E_KEY_NAME_HAS_SPACE: 'This key name has only space character. Please choose another name.',
    E_SPECIAL_CHARS:
      'Please use alphabetical letters, numbers, space, dash, and underscore for your key name.',
    E_FILL_KEY_NAME: 'Please fill in the key name.',
    E_URL_INVALID: 'The URL is not valid!',
    E_USER_NOT_EXIST: 'User does not exist.',
    E_COPY_FAILED: 'Failed to copy. Something is wrong with the system.',
    E_DELETE_AP_KEY: 'Error! The access key can’t be deleted! Something is wrong with the system.',
    E_CHANGE_STATUS_API_KEY_INACTIVATE:
      'Error! The access key can’t be deactivated! Something is wrong with the system.',
    E_CHANGE_STATUS_API_KEY_ACTIVATE:
      'Error! The access key can’t be activated! Something is wrong with the system.',
    E_CANNOT_UPDATE_LAST_READ_RECORD: 'Fail to update the last read record.',
    E_REQUIRED_FIELD: 'Please fill in this field before submitting.',
    E_INVALID_FIELD: 'Please fill in a valid name, which only contains alphabetical letters.',
    E_INVALID_NAME:
      'Please fill in a valid name, which only contains alphabetical letters and numbers.',
    E_INVALID_KEY_NAME:
      'Please fill in a valid key, which only contains alphabetical letters and numbers.',
    E_INVALID_CONTACT_NAME:
      'Please fill in a valid name, which only contains alphabetical letters, numbers, and spaces.',
    E_INVALID_EMAIL: 'Please fill in a valid email.',
    E_INVALID_PHONE_NUMBER: 'Please fill in a valid American phone number.',
    E_INVALID_CITY: 'Please fill in a valid city name, which only contains alphabetical letters.',
    E_INVALID_STATE: 'Please fill in a valid state, which only contains alphabetical letters.',
    E_INVALID_ZIPCODE: 'Please fill in a ZIP code, which is a 5-digit code.',
    E_INVALID_ADDRESS:
      "Please fill in a valid address, which contains the alphabetical letters, numbers, and the following special characters: ^[#.-s,-]+$'/",
    E_MAX_LENGTH:
      'Your name should not be longer than 200 characters. Please fill in a shorter one.',
    E_CREATE_ACCOUNT: 'Something went wrong with the server. Please try again later.',
    E_SHOW_ACTIVITIES_LOG: 'Failed to loading activities log. Something is wrong with the system.',
    E_FILED_REQUIRED: 'This field is require.',
    E_UPDATED_WEBHOOK_SETTING:
      'The configuration table can’t be updated. Please put in only the positive integer number.',
    E_PUSHED_WEBHOOK: 'Something went wrong! The message can’t be sent to the webhook URL.',
    E_SHOW_ACTIVITIES_TYPE:
      'Failed to loading activities type. Something is wrong with the system.',
    E_SHOW_JSON_PACKET: 'Failed to loading JSON packet. Something is wrong with the system.',
    E_SOMETHING_WENT_WRONG: 'Something is wrong with the system.',
    E_SHOW_CARRIER_TYPE: 'Failed to loading carrier. Something is wrong with the system.',
    E_LOGOUT: 'Error! Can not logout! Something is wrong with the system.',
    E_PASSWORD_UPDATE_WEBHOOK:
      'Something went wrong. The password failed to be added. Please try again later.',
    E_PASSWORD_INVALID:
      'Please put in a valid password. It can’t contain space or invisible characters.',
    E_PASSWORD_MINLENGTH: 'The password needs to contain at least 8 characters.',
    E_REQUEST_ACC_TO_PRODUCTION:
      'Something went wrong. The request failed to send. Please try again later.',
    E_UPDATE_CARRIER: 'Failed to update carrier. Something is wrong with the system.',
    E_CHANGE_KEY_NAME:
      'These change can’t be updated! Something went wrong with the server. Please try again later.',
    E_UPDATE_CARRIER_INFO:
      'Failed to update carrier information. Something is wrong with the system.',
    E_DELETE_USER: 'Something went wrong. Please try again later.',
    E_BLOCK_USER: 'Something went wrong. Please try again later.',
    E_UNBLOCK_USER: 'Something went wrong. Please try again later.',
    E_USERNAME_EXISTED: 'This username existed, please try another one.',
    E_TRY_AGAIN: 'Something went wrong. Please try again later.',
    E_EDIT_MY_ACCOUNT: 'Failed to update my information. Something is wrong with the system.',
    E_CREATED_GROUP_P_TO_PACC: 'Something went wrong! Please try again later.',
    E_DUPLICATED_P_ACCOUNT: 'You chose the same P.Account to group to. Please choose another one.',
    E_CREATED_CLIENT_CREDENTIAL: 'Something went wrong. Please try again later.',
    E_DELETE_CLIENT_CREDENTIAL: 'Something went wrong. Please try again later.',
    E_INVALID_URL: 'Please input an URL.',
    E_COMPULSORY: 'This field is compulsory.',
    E_SOMETHING_WENT_WRONG_SYSTEM: 'Something went wrong with the server. Please try again later.',
    E_UPDATE_AGENCY_KEY: 'Something went wrong! Please try again later.',
    E_UPDATE_AGENT_KEY: 'Something went wrong! Please try again later.',
    E_DUPLICATE_AGENCY_KEY: 'This Agency key is duplicated, please input another key.',
    E_DUPLICATE_AGENT_KEY: 'This Agent key is duplicated, please input another key.',
    E_ADD_AMS_TO_THE_SYSTEM: 'This AMS is already in the system, please check again!',
    E_CHANGE_STATUS_CONFIGURATION_INACTIVATE:
      'Error! The configuration can’t be deactivated! Something is wrong with the system.',
    E_CHANGE_STATUS_CONFIGURATION_ACTIVATE:
      'Error! The configuration can’t be activated! Something is wrong with the system.',
  },
  INFO: {
    I_COPIED_KEY: 'Copied Access Key!',
    I_COPIED_JSON_PACKET: 'Copied Json Packet!',
    I_COPIED_JSON_RESPONSE: 'Copied Json Respoonse!',
    I_COPIED_PRINCIPAL_KEY: 'Copied Principal Key!',
    I_COPIED_PRINCIPAL_ACCOUNT_KEY: 'Copied Principal Account Key!',
  },
}

export enum STATUS {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
}
