import storage from 'redux-persist/lib/storage'
import {
  ActivitiesGroupModel,
  JsonPacketGroupModel,
} from '../../pages/developer-portal/table/activities-log/component/activitiesLogModel'
import { persistReducer } from 'redux-persist'
import {
  ADMIN_ACTIVITIES_TYPE_GROUP,
  ADMIN_REQUEST_CARRIER,
  ADMIN_REQUEST_FROM_DATE,
  ADMIN_REQUEST_JSON_PACKET,
  ADMIN_REQUEST_TO_DATE,
  CLEAR_DATETIME_FILTER,
  CLEAR_SEARCH_PRINCIPAL,
  CLEAR_VALUE_SEARCH_PRINCIPAL_ADDRESS,
  CLEAR_VALUE_SEARCH_PRINCIPAL_NAME,
  SEARCH_PRINCIPAL,
  VALUE_SEARCH_PRINCIPAL_ADDRESS,
  VALUE_SEARCH_PRINCIPAL_NAME,
  OPEN_PRINCIPAL_ACCOUNT,
  CLOSE_PRINCIPAL_ACCOUNT,
  GET_PRINCIPAL_ACCOUNT_DETAIL,
  CLEAR_PRINCIPAL_ACCOUNT_DETAIL,
  GET_PRINCIPAL_ACCOUNT_INFO_DETAIL,
  CLEAR_PRINCIPAL_ACCOUNT_INFO_DETAIL,
  CLEAR_TAKE_ITEM_SEARCH_PRINCIPAL_ACCOUNT,
  UPDATE_TAKE_ITEM_SEARCH_PRINCIPAL_ACCOUNT,
  COUNT_SEARCH_PRINCIPAL_ACCOUNT,
  CLEAR_COUNT_SEARCH_PRINCIPAL_ACCOUNT,
} from '../actions/actionTypes'
import { CarrierGroupModel } from '../../pages/developer-portal/table/activities-log/component/carrierModel'

type activitiesState = {
  carrierGroupType: CarrierGroupModel[]
  activitiesGroupType: ActivitiesGroupModel[]
  requestJsonPacket: JsonPacketGroupModel[]
  fromDate: object | any
  fromDateByAMS: object | any
  toDate: object | any
  toDateByAMS: object | any
  searchPrincipal?: any
  loadMoreSearchPrincipal: number
  valuePrincipalName?: string
  valuePrincipalAddress?: string
  isOpenPrincipalAccountDetail?: boolean
  principalAccountName?: string
  principalAccountDetailData?: any
  principalAccountDetailInfo?: any
  takeItemSearchPrincipalAccount?: number
  countSearchPrincipalAccount?: number
}

const initialState: activitiesState = {
  carrierGroupType: [],
  activitiesGroupType: [],
  requestJsonPacket: [],
  searchPrincipal: {},
  valuePrincipalName: '',
  valuePrincipalAddress: '',
  loadMoreSearchPrincipal: 0,
  takeItemSearchPrincipalAccount: 4,
  countSearchPrincipalAccount: 0,
  fromDate: {
    date: null,
    time: {
      hour: '00',
      minute: '00',
      second: '00',
      millisecond: '',
    },
  },
  toDate: {
    date: null,
    time: {
      hour: '00',
      minute: '00',
      second: '00',
      millisecond: '',
    },
  },
  fromDateByAMS: {
    date: null,
    time: {
      hour: '00',
      minute: '00',
      second: '00',
      millisecond: '',
    },
  },
  toDateByAMS: {
    date: null,
    time: {
      hour: '00',
      minute: '00',
      second: '00',
      millisecond: '',
    },
  },
}

/**
 *
 * @param state
 * @param param1
 * @returns
 */
const adminPortalReducer = (state = initialState, { type, payload, loadMore }: any) => {
  switch (type) {
    case ADMIN_REQUEST_CARRIER:
      return {
        ...state,
        carrierGroupType: payload,
      }
    case ADMIN_ACTIVITIES_TYPE_GROUP:
      return {
        ...state,
        activitiesGroupType: payload,
      }
    case ADMIN_REQUEST_JSON_PACKET:
      return {
        ...state,
        requestJsonPacket: payload,
      }
    case ADMIN_REQUEST_FROM_DATE:
      return {
        ...state,
        fromDate: payload,
      }
    case ADMIN_REQUEST_TO_DATE:
      return {
        ...state,
        toDate: payload,
      }
    case CLEAR_DATETIME_FILTER:
      return {
        ...state,
        fromDate: null,
        toDate: null,
      }
    case SEARCH_PRINCIPAL:
      return {
        ...state,
        searchPrincipal: payload,
        loadMoreSearchPrincipal: loadMore,
      }
    case CLEAR_SEARCH_PRINCIPAL:
      return {
        ...state,
        searchPrincipal: {},
        loadMoreSearchPrincipal: 0,
      }
    case VALUE_SEARCH_PRINCIPAL_NAME:
      return {
        ...state,
        valuePrincipalName: payload,
      }
    case CLEAR_VALUE_SEARCH_PRINCIPAL_NAME:
      return {
        ...state,
        valuePrincipalName: '',
      }
    case VALUE_SEARCH_PRINCIPAL_ADDRESS:
      return {
        ...state,
        valuePrincipalAddress: payload,
      }
    case CLEAR_VALUE_SEARCH_PRINCIPAL_ADDRESS:
      return {
        ...state,
        valuePrincipalAddress: '',
      }
    case OPEN_PRINCIPAL_ACCOUNT:
      return {
        ...state,
        isOpenPrincipalAccountDetail: true,
        principalAccountName: payload,
      }
    case CLOSE_PRINCIPAL_ACCOUNT:
      return {
        ...state,
        isOpenPrincipalAccountDetail: false,
        principalAccountName: '',
      }
    case GET_PRINCIPAL_ACCOUNT_DETAIL:
      return {
        ...state,
        principalAccountDetailData: payload,
      }
    case CLEAR_PRINCIPAL_ACCOUNT_DETAIL:
      return {
        ...state,
        principalAccountDetailData: [],
      }
    case GET_PRINCIPAL_ACCOUNT_INFO_DETAIL:
      return {
        ...state,
        principalAccountDetailInfo: payload,
      }
    case CLEAR_PRINCIPAL_ACCOUNT_INFO_DETAIL:
      return {
        ...state,
        principalAccountDetailInfo: [],
      }
    case UPDATE_TAKE_ITEM_SEARCH_PRINCIPAL_ACCOUNT:
      return {
        ...state,
        takeItemSearchPrincipalAccount: payload,
      }
    case CLEAR_TAKE_ITEM_SEARCH_PRINCIPAL_ACCOUNT:
      return {
        ...state,
        takeItemSearchPrincipalAccount: 4,
      }
    case COUNT_SEARCH_PRINCIPAL_ACCOUNT:
      return {
        ...state,
        countSearchPrincipalAccount: (state.countSearchPrincipalAccount as number) + 1,
      }
    case CLEAR_COUNT_SEARCH_PRINCIPAL_ACCOUNT:
      return {
        ...state,
        countSearchPrincipalAccount: 0,
      }
    default:
      return state
  }
}

const persistConfig = {
  key: 'activitiesLogForAdmin',
  storage: storage,
}

export default persistReducer(persistConfig, adminPortalReducer)
