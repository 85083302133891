import React, { useEffect, useState } from 'react'
import Card from '../../../components/card'
import { useQuery } from '@apollo/client'
import { GET_CARRIER_INFO } from '../../../queries/adminPortal'
import { CODE_TIMEOUT } from '../../../constants/statusCode'
import { useSession } from '../../../helpers/checkSession'
import Loading from '../../../components/loading'
import { BusinessSponsorInfo, DeveloperInfo } from './models'
import { useLocation } from 'react-router-dom'
import { LocationState } from '../../../constants/interface'

const CarrierInfo = () => {
  const { timeOutSession } = useSession()
  const [businessSponsorInfo, setBusinessSponsorInfo] = useState<BusinessSponsorInfo>()
  const [developerInfo, setDeveloperInfo] = useState<DeveloperInfo[]>([])
  const { state } = useLocation()
  const locationState = state as LocationState

  const { data, loading, error, refetch } = useQuery(GET_CARRIER_INFO, {
    fetchPolicy: 'no-cache',
    variables: {
      data: {
        partyId: locationState?.partyId,
        kind: locationState?.kind,
      },
    },
  })

  const handleRefetchData = () => {
    refetch()
  }

  const handleShowOverflow = () => {
    // enable scroll when dialog is closed
    document.body.style.overflow = 'overlay'
  }

  useEffect(() => {
    if (error?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT) {
      timeOutSession()
    }
    setBusinessSponsorInfo(data?.getPartyWithSponsorAndUsers?.businessSponsor)
    setDeveloperInfo(data?.getPartyWithSponsorAndUsers?.users)
  }, [error, data])

  if (loading) return <Loading />

  return (
    <div className="px-20 py-10 md:px10">
      <p className="text-headline5A text-neutral-1">Business Management information</p>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-1 gap-x-6 gap-y-10">
        <Card
          isEditCarrierInfo={true}
          avatar=""
          firstName={businessSponsorInfo?.businessSponsorFirstName as string}
          lastName={businessSponsorInfo?.businessSponsorLastName as string}
          title="Business management"
          businessMail={businessSponsorInfo?.businessSponsorEmail}
          phoneNumber={businessSponsorInfo?.businessSponsorPhoneNumber}
          hiddenBlock="hidden"
          userInfo={businessSponsorInfo}
          isBusinessInfo={true}
          handleRefetchData={handleRefetchData}
          isShowEditAction={true}
          isJustEdit={true}
          handleShowOverflow={handleShowOverflow}
        />
      </div>

      <hr className="my-10 border-t-neutral-6" />
      <p className="text-headline5A text-neutral-1">Developer information</p>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-1 gap-x-6">
        {developerInfo?.map((item, idx) => (
          <div key={idx} className="mb-1">
            <Card
              isEditCarrierInfo={true}
              avatar={item?.avatar}
              firstName={item?.firstName}
              lastName={item?.lastName}
              title="Developer"
              businessMail={item?.email}
              propellerMail={item?.gsuiteUsername}
              phoneNumber={item?.phoneNumber}
              userInfo={item}
              isBusinessInfo={false}
              handleRefetchData={handleRefetchData}
              isShowEditAction={true}
              handleShowOverflow={handleShowOverflow}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default CarrierInfo
