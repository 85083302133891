import React, { useEffect, useRef, useState } from 'react'
import MiniLoading from '../mini-loading'

export type Option = {
  id: number | string
  name: string
  kind: string
}

type Props = {
  options: Option[]
  handleOptions?: (option: Option, name?: string, id?: string, kind?: string) => void
  name?: string
  initialValue?: Option | any
  placeHolder?: string
  id?: string
  loading?: boolean
  userIdChanged?: string
  disabled?: boolean
}

const SelectionBase: React.FC<Props> = ({
  options,
  handleOptions = () => null,
  name,
  initialValue,
  placeHolder,
  id,
  loading,
  userIdChanged,
  disabled,
}) => {
  const [showListOptions, setShowListOptions] = useState<boolean>(false)
  const [currentValue, setCurrentValue] = useState<Option>({
    id: 0,
    name: initialValue?.name || placeHolder,
    kind: '',
  })
  const [selected, setSelected] = useState<boolean>(false)

  const dropdownRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const onHandleClickOutSide = (e: any) => {
      if (dropdownRef && !dropdownRef.current?.contains(e.target)) {
        setShowListOptions(false)
      }
    }
    document.addEventListener('click', onHandleClickOutSide)
    return () => {
      document.removeEventListener('click', onHandleClickOutSide)
    }
  }, [showListOptions])

  return (
    <div>
      <div className="relative inline-block text-left w-[100%]">
        <div>
          <button
            type="button"
            className={`inline-flex items-center shadow-sm bg-white text-sm font-medium w-[100%] h-8 justify-between gap-3
            border-b-[1.5px] border-neutral-5`}
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
            onClick={() => {
              setShowListOptions(disabled ? false : !showListOptions)
            }}
            ref={dropdownRef}
          >
            <span
              className={`h-6 font-montserrat pl-2 text-base w-[150px] overflow-hidden text-ellipsis whitespace-nowrap text-left ${
                selected || initialValue?.name
                  ? 'text-neutral-2'
                  : 'font-medium text-base text-neutral-5'
              }`}
            >
              {loading && userIdChanged === id ? <MiniLoading /> : currentValue?.name}
            </span>
          </button>
        </div>
        {showListOptions && (
          <div
            className="origin-top-right small-scrollbar absolute right-0 top-[38px] focus:outline-none w-[230px] max-h-[250px] rounded-xl p-2 bg-white-50 border border-neutral-7 shadow-1xl z-10 overflow-auto"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabIndex={-1}
          >
            <div className="time-scrollbar z-50" role="none">
              {options &&
                options.map((option) => (
                  <div
                    key={option.id}
                    className="text-gray-700 block px-4 py-2 font-medium text-sm hover:bg-neutral-7 w-[100%] h-10 top-2 left-2 rounded-lg p-2 cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap"
                    role="menuitem"
                    tabIndex={-1}
                    id="menu-item-0"
                    onClick={() => {
                      handleOptions(option, name, id)
                      setCurrentValue(option)
                      setShowListOptions(!showListOptions)
                      setSelected(true)
                    }}
                  >
                    <span className="h-6 text-neutral-3 text-body1 w-[100px] overflow-hidden text-ellipsis whitespace-nowrap">
                      {option?.name}
                    </span>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SelectionBase
