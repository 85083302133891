import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import IconLogo from '../../assets/images/logo-propeller-full.svg'
import Avatar from '../../assets/images/avatar-example.svg'
import ButtonSignIn from '../sign-in'
import ButtonSignOut from '../sign-out'
import { RootState } from '../../store'
import { RoleType } from '../../constants/interface'
import { KIND, ROLE } from '../../constants/common'
import {
  closePrincipalAccountDetail,
  clearPrincipalAccountInfoDetail,
  clearPrincipalAccountDetail,
  clearCountSearchPrincipalAccount,
} from '../../redux/actions/adminPortal'
import { useWindowSize } from '../../helpers/useWindowSize'
import iconDownArrow from '../../assets/images/icon-down-arrow.svg'
import iconUpArrow from '../../assets/images/icon-up-arrow.svg'
import { screens } from '../../constants/screens'

const Header = () => {
  const userInfoState = useSelector((state: RootState) => state.userReducers?.userInfo)
  const dropDownRef = useRef<HTMLDivElement>(null)
  const dropDownMenuRef = useRef<HTMLUListElement>(null)
  const [isPopupDropDown, setIsPopupDropDown] = useState<boolean>(false)
  const btnRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const [isPopup, setIsPopup] = useState<boolean>(false)
  const [isActiveDropDown, setIsActiveDropDown] = useState<boolean>(false)
  const [avatar, setAvatar] = useState<string>(Avatar)

  const accessTokenGg = localStorage.getItem('accessTokenGg')
  const isRoleAdmin = ROLE[userInfoState?.role?.name as keyof RoleType] === ROLE.Admin
  const currentRole = userInfoState?.role?.name

  const dispatch = useDispatch()

  const windowSize = useWindowSize()
  const handleClick = () => {
    setIsPopup(!isPopup)
  }

  const handleClickDropDown = () => {
    setIsPopupDropDown(!isPopupDropDown)
  }

  const handleClickActiveDrop = (isActiveDropDown = true) => {
    setIsActiveDropDown(isActiveDropDown)
    setIsPopupDropDown(false)
  }

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (
        menuRef &&
        !menuRef.current?.contains(e.target) &&
        btnRef &&
        !btnRef.current?.contains(e.target)
      ) {
        setIsPopup(false)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      // Remove click outside
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isPopup])

  useEffect(() => {
    const handleOutsideDropDownClick = (e: any) => {
      if (
        dropDownMenuRef &&
        !dropDownMenuRef.current?.contains(e.target) &&
        btnRef &&
        !dropDownRef.current?.contains(e.target)
      ) {
        setIsPopupDropDown(false)
      }
    }

    document.addEventListener('click', handleOutsideDropDownClick)

    return () => {
      document.removeEventListener('click', handleOutsideDropDownClick)
    }
  }, [isPopupDropDown])

  useEffect(() => {
    setAvatar(userInfoState?.avatar)
  }, [userInfoState])

  // handle clear principal account's data when navigate with admin role and home page
  const handleClearInfoPrincipalAccount = (isActiveDropDown?: boolean) => {
    handleClickActiveDrop(isActiveDropDown)
    dispatch(closePrincipalAccountDetail())
    dispatch(clearPrincipalAccountInfoDetail())
    dispatch(clearPrincipalAccountDetail())
    dispatch(clearCountSearchPrincipalAccount())
  }

  const BASE_MODULE_NAME = ['my-account']

  const ADMIN_MODULE_NAME = [
    'system-monitoring',
    'users-management',
    'system-configuration',
    'principal-account-management',
    'email-system',
  ]
  const ACCOUNT_MANAGER_MODULE_NAME = ['activities-management', 'principal-account-management']
  const DEVELOPER_MODULE_NAME = ['activities-management', 'development-management']
  const AMS_MODULE_NAME = ['my-agencies']

  const checkHeaderVisibleByRole = (moduleName: string): boolean => {
    if (userInfoState?.kind === KIND.AGENCIES) {
      return AMS_MODULE_NAME.concat(BASE_MODULE_NAME).includes(moduleName)
    }
    switch (currentRole) {
      case ROLE.Admin:
        return ADMIN_MODULE_NAME.concat(BASE_MODULE_NAME).includes(moduleName)
      case ROLE.AccountManager:
        return ACCOUNT_MANAGER_MODULE_NAME.concat(BASE_MODULE_NAME).includes(moduleName)
      case ROLE.Developer:
        return DEVELOPER_MODULE_NAME.concat(BASE_MODULE_NAME).includes(moduleName)
      case ROLE.DeveloperAccountManager:
        return ACCOUNT_MANAGER_MODULE_NAME.concat(DEVELOPER_MODULE_NAME)
          .concat(BASE_MODULE_NAME)
          .includes(moduleName)
      default:
        return false
    }
  }

  const LIST_DEVELOPER_MODULES = [
    {
      key: 1,
      path: '/activities-management',
      displayName: 'Activities management',
      onClick: () => setIsActiveDropDown(false),
      screen: 'all'
    },
    {
      key: 2,
      path: '/development-management',
      displayName: 'Development management',
      onClick: () => setIsActiveDropDown(false),
      screen: 'all'
    },
    {
      key: 3,
      path: '/principal-account-management',
      displayName: 'Principal Account management',
      onClick: () => handleClearInfoPrincipalAccount(false),
      screen: 'all'
    },
    {
      key: 4,
      path: '/my-agencies',
      displayName: 'My Agencies',
      onClick: () => setIsActiveDropDown(false),
      screen: 'all'
    },
    {
      key: 5,
      path: '/my-account',
      displayName: 'My account',
      onClick: () => handleClearInfoPrincipalAccount(),
      screen: 'all'
    },
  ]

  const LIST_ADMIN_MODULES = [
    {
      key: 'ADMIN_1',
      path: '/system-monitoring',
      displayName: 'System monitoring',
      onClick: () => handleClearInfoPrincipalAccount(false),
      screen: 'all',
    },
    {
      key: 'ADMIN_2',
      path: '/users-management',
      displayName: 'Users management',
      onClick: () => handleClearInfoPrincipalAccount(false),
      screen: 'all',
    },
    {
      key: 'ADMIN_3',
      path: '/system-configuration',
      displayName: 'System configuration',
      onClick: () => handleClearInfoPrincipalAccount(false),
      screen: 'lg',
    },
    {
      key: 'ADMIN_4',
      path: '/principal-account-management',
      displayName: 'Principal Account management',
      onClick: () => handleClearInfoPrincipalAccount(false),
      screen: 'lg',
    },
  ]

  const ADMIN_DROP_DOWN_MODULE = [
    {
      key: 'DROPDOWN_1',
      path: '/principal-account-management',
      displayName: 'Principal Account management',
      onClick: () => handleClickActiveDrop(),
      screen: 'md',
    },
    {
      key: 'DROPDOWN_2',
      path: '/system-configuration',
      displayName: 'System configuration',
      onClick: () => handleClickActiveDrop(),
      screen: 'md',
    },
    {
      key: 'DROPDOWN_3',
      path: '/email-system',
      displayName: 'Email System',
      onClick: () => handleClickActiveDrop(),
      screen: 'all',
    },
    {
      key: 'DROPDOWN_4',
      path: '/my-account',
      displayName: 'My Account',
      onClick: () => handleClickActiveDrop(),
      screen: 'all',
    },
  ]

  const developerHeaders = () => {
    return (
      <>
        {LIST_DEVELOPER_MODULES.map((item) =>
          checkHeaderVisibleByRole(item.path.split('/')[1]) ? (
            <li
              key={item.key}
              onClick={item.onClick}
              className={`flex items-center mx-3 relative hover:text-primary-shade2 md:max-w-[200px]`}
            >
              <NavLink
                className={({ isActive }) =>
                  `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                    isActive && 'before:w-full text-primary-shade1'
                  }`
                }
                to={item.path}
              >
                {item.displayName}
              </NavLink>
            </li>
          ) : null,
        )}
      </>
    )
  }

  const adminHeaders = (sizeScreen: 'lg' | 'md' | 'sm' = 'lg') => {
    return (
      <>
        {LIST_ADMIN_MODULES.filter(
          (item) => item.screen === 'all' || item.screen === sizeScreen,
        ).map((item) =>
          checkHeaderVisibleByRole(item.path.split('/')[1]) ? (
            <li
              key={item.key}
              className="relative flex items-center mx-3 hover:text-primary-shade2"
            >
              <NavLink
                className={({ isActive }) =>
                  `before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
                    isActive && 'before:w-full text-primary-shade1'
                  }`
                }
                to={item.path}
                onClick={item.onClick}
              >
                {item.displayName}
              </NavLink>
            </li>
          ) : null,
        )}
        <li className="relative py-2 pt-6 pl-3 pr-4">
          <div
            ref={dropDownRef}
            onClick={handleClickDropDown}
            className={`flex items-center cursor-pointer hover:text-primary-shade2 md:pr-6 rounded-full before:absolute before:border-t-4 before:rounded-tl-[4px] before:rounded-tr-[4px] before:border-t-primary-shade2 before:bottom-0 hover:w-full ${
              isActiveDropDown ? 'before:w-11 text-primary-shade1' : ''
            }`}
          >
            More
            {isPopupDropDown ? (
              <img src={iconUpArrow} alt="iconUpArrow" width={20} height={20} />
            ) : (
              <img src={iconDownArrow} alt="iconDownArrow" width={20} height={20} />
            )}
          </div>
          {isPopupDropDown && (
            <ul
              ref={dropDownMenuRef}
              className="absolute left-[-4px] md:left-[4px] mt-4 w-[310px] rounded-xl bg-neutral-8 shadow-[0_12px_24px_rgba(0,0,0,0.2)] border border-neutral-7"
            >
              {ADMIN_DROP_DOWN_MODULE.filter(
                (item) => item.screen === 'all' || item.screen === sizeScreen,
              ).map((item) =>
                checkHeaderVisibleByRole(item.path.split('/')[1]) ? (
                  <li
                    key={item.key}
                    className="flex items-center mx-3 relative hover:text-primary-shade2 h-[40px]"
                  >
                    <NavLink
                      onClick={item.onClick}
                      className={({ isActive }) =>
                        ` hover:w-full ${
                          isActive &&
                          'ml-4 before:absolute before:min-h-[6px] before:top-[50%] before:translate-y-[-50%] before:-left-0 before:min-w-[6px] before:bg-purple-900 before:rounded-full text-primary-shade1'
                        }`
                      }
                      to={item.path}
                    >
                      {item.displayName}
                    </NavLink>
                  </li>
                ) : null,
              )}
            </ul>
          )}
        </li>
      </>
    )
  }

  return (
    <header className="w-full max-w-[1440px] flex items-center px-20 h-[72px] justify-between border-b border-b-neutral-6 bg-neutral-7-header-footer fixed z-20">
      <div className="flex items-center h-full">
        <NavLink to="/" onClick={() => handleClearInfoPrincipalAccount()}>
          <img src={IconLogo} alt="Logo" className="max-w-[110px] h-[40px] mr-5 md:ml-[-40px]" />
        </NavLink>
        <ul className="flex h-full text-body1 text-neutral-4 pl-[48px] md:pl-[28px]">
          {Number(windowSize.width) <= screens.lg
            ? //  Medium screen
              accessTokenGg && isRoleAdmin
              ? adminHeaders('md')
              : developerHeaders()
            : //  Large Screen
            Number(windowSize.width) > screens.lg && accessTokenGg && isRoleAdmin
            ? adminHeaders()
            : developerHeaders()}
        </ul>
      </div>
      <div className="flex items-center">
        {accessTokenGg || userInfoState ? (
          <div className="relative">
            <div
              ref={btnRef}
              onClick={handleClick}
              className="flex items-center rounded-full cursor-pointer md:mr-[-42px]"
            >
              <img className="max-w-[40px] h-10 rounded-full" src={avatar} alt="Avatar" />
            </div>
            {isPopup && (
              <div
                ref={menuRef}
                className="absolute right-0 mt-2 md:mr-[-40px] rounded-xl bg-neutral-8 p-3 w-60 shadow-[0_12px_24px_rgba(0,0,0,0.2)] border border-neutral-7"
              >
                <div className="p-2 pb-3 mb-1 border-b text-neutral-3 border-b-neutral-6">
                  <p className="font-semibold text-body1">{`${userInfoState?.firstName} ${userInfoState?.lastName}`}</p>
                  <p className="font-medium text-body3">{userInfoState?.role?.name}</p>
                </div>
                <div className="p-1">
                  <ButtonSignOut
                    className="justify-start hover:bg-transparent"
                    onClosePopup={() => setIsPopup(false)}
                  />
                </div>
              </div>
            )}
          </div>
        ) : (
          <ButtonSignIn />
        )}
      </div>
    </header>
  )
}

export default Header
