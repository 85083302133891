import React from 'react'
import classNames from 'classnames'

import { RegisterOptions, DeepMap, FieldError, UseFormRegister, Path, FieldValues } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import InputBase, { InputProps } from './inputBase'
import { FormErrorMessage } from './errorMessageBase'
import { MESSAGES } from '../../constants/messages'
import { get } from 'lodash'

export type FormInputProps<TFormValues extends FieldValues> = {
  name: Path<TFormValues>
  rules?: RegisterOptions
  register?: UseFormRegister<TFormValues>
  errors?: Partial<DeepMap<TFormValues, FieldError>>
  inputClassName?: string
  disabled?: boolean
} & Omit<InputProps, 'name'>

export const FormInput = <TFormValues extends Record<string, unknown>>({
  name,
  register,
  rules,
  errors,
  className,
  inputClassName,
  disabled,
  ...props
}: FormInputProps<TFormValues>): JSX.Element => {
  // If the name is in a FieldArray, it will be 'fields.index.fieldName' and errors[name] won't return anything, so we are using lodash get
  const errorMessages = get(errors, name)
  const hasError = !!(errors && errorMessages)

  return (
    <div className={classNames('', className)} aria-live="polite">
      <InputBase
        isError={hasError}
        name={name}
        aria-invalid={hasError}
        className={classNames(
          {
            'border-red-900 focus:border-red-900': hasError,
          },
          inputClassName,
        )}
        disabled={disabled}
        {...props}
        {...(register && register(name, rules))}
      />
      {errors && <ErrorMessage
        errors={errors}
        name={name as any}
        render={({ message }) => (
          <FormErrorMessage className="mt-1">
            {errors && (errors as any)[name]?.[name]?.type === 'maxLength' ? MESSAGES.ERROR.E_MAX_LENGTH : message}
          </FormErrorMessage>
        )}
      />}

    </div>
  )
}
