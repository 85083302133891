import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'

import ButtonBase from '../../../components/partials/button'
import AddPrincipalAccount from '../dialog/addPrincipalAccount'
import GroupPAccountToPAccount from '../dialog/groupPAccountToPAccount'
import AutocompletedInput from './autocompletedInput'
import GroupPrincipalToPAccount from '../dialog/groupPrincipalAccount'
import Dropdown, { OptionType } from './dropdown'

const options: OptionType[] = [
  {
    label: 'Principal Account',
    value: '1',
  },
  {
    label: 'Principal',
    value: '2',
  },
]
const GroupAccount = () => {
  const [modalIsOpen, setIsModelOpen] = useState<boolean>(false)
  const searchBoxRef = useRef<HTMLDivElement>(null)
  const [isFocus, setIsFocus] = useState<boolean>(false)
  const searchBoxRefTo = useRef<HTMLDivElement>(null)
  const [isFocusTo, setIsFocusTo] = useState<boolean>(false)
  const [isValueInputPrincipalSelected, setIsValueInputPrincipalSelected] = useState<{
    idPrincipal: string
    namePrincipal: string
  }>()
  const [isValueInputPAccountSelectedFrom, setIsValueInputPAccountSelectedFrom] = useState<{
    idPrincipalAccountFrom: string
    namePrincipalAccountFrom: string
  }>()
  const [isValueInputPAccountSelectedTo, setIsValueInputPAccountSelectedTo] = useState<{
    idPrincipalAccountTo: string
    namePrincipalAccountTo: string
  }>()
  const [dropdownValue, setDropdownValue] = useState<OptionType>(options[0])
  const [isDisable, setIsDisable] = useState<boolean>(true)

  const [checkValueInputPFrom, setCheckValueInputPFrom] = useState<string>()
  const [checkValueInputPTo, setCheckValueInputPTo] = useState<string>()

  useEffect(() => {
    if (dropdownValue.value === options[0].value) {
      if (
        isValueInputPAccountSelectedFrom?.idPrincipalAccountFrom &&
        isValueInputPAccountSelectedTo?.idPrincipalAccountTo
      ) {
        setIsDisable(false)
      } else {
        setIsDisable(true)
      }
    }
    if (dropdownValue.value === options[1].value) {
      if (isValueInputPrincipalSelected?.idPrincipal) {
        setIsDisable(false)
      } else {
        setIsDisable(true)
      }
    }
  }, [
    isValueInputPAccountSelectedFrom,
    isValueInputPAccountSelectedTo,
    isValueInputPrincipalSelected,
  ])

  useEffect(() => {
    if (dropdownValue.value === options[0].value) {
      setIsDisable(true)
    }
    if (dropdownValue.value === options[1].value) {
      if (checkValueInputPTo) {
        setIsDisable(true)
      } else {
        setIsDisable(false)
      }
    }
  }, [checkValueInputPFrom, checkValueInputPTo])

  const handleSubmitPrincipal = () => {
    setIsDisable(true)
  }

  const handleClearPrincipalSelected = () => {
    if (dropdownValue.value === options[1].value) {
      setIsValueInputPrincipalSelected({ idPrincipal: '', namePrincipal: '' })
      setIsDisable(true)
    }
  }

  const handleClearPAccTo = () => {
    setIsValueInputPAccountSelectedTo({ idPrincipalAccountTo: '', namePrincipalAccountTo: '' })
    setIsDisable(true)
  }

  useEffect(() => {
    if (
      dropdownValue.value === options[1].value &&
      !isValueInputPrincipalSelected?.idPrincipal &&
      !isValueInputPAccountSelectedTo?.idPrincipalAccountTo
    ) {
      setIsDisable(true)
    }
  }, [isValueInputPrincipalSelected, isValueInputPAccountSelectedTo])

  useEffect(() => {
    const onHandleClickOutSide = (e: MouseEvent) => {
      if (searchBoxRef && !searchBoxRef.current?.contains(e.target as HTMLDivElement)) {
        setIsFocus(false)
      }
      if (searchBoxRefTo && !searchBoxRefTo.current?.contains(e.target as HTMLDivElement)) {
        setIsFocusTo(false)
      }
    }

    document.addEventListener('click', onHandleClickOutSide)
    return () => {
      document.removeEventListener('click', onHandleClickOutSide)
    }
  }, [isFocus, isFocusTo])

  useEffect(() => {
    setIsValueInputPrincipalSelected({
      idPrincipal: '',
      namePrincipal: '',
    })
  }, [dropdownValue.value === options[0].value])

  return (
    <>
      <p className="text-headline5A text-neutral-1">Group Account</p>
      <div className="flex justify-between items-center mt-5 mb-5">
        <div
          className={classNames('flex w-full h-[56px] rounded-xl border-[2px]', {
            'border-neutral-3': isFocus,
            'border-neutral-5': !isFocus,
          })}
          ref={searchBoxRef}
        >
          <Dropdown
            options={options}
            classbtn="border-0 border-r-2 rounded-none"
            setIsFocus={setIsFocus}
            setDropdownValue={setDropdownValue}
          />
          <AutocompletedInput
            classInput="border-0 w-full"
            setIsFocus={setIsFocus}
            dropdownValue={dropdownValue}
            setIsValueInputPrincipalSelected={setIsValueInputPrincipalSelected}
            setIsValueInputPAccountSelectedFrom={setIsValueInputPAccountSelectedFrom}
            checkValueInput={setCheckValueInputPFrom}
            handleClearPrincipalSelected={handleClearPrincipalSelected}
            isHandleSearch={true}
          />
        </div>
      </div>
      <div className="flex justify-between items-center mt-5 mb-5">
        <div
          className={classNames('flex w-full h-[56px] rounded-xl border-[2px]', {
            'border-neutral-3': isFocusTo,
            'border-neutral-5': !isFocusTo,
          })}
          ref={searchBoxRefTo}
        >
          <p className="py-3 pl-4 pr-4 border-0 border-r-2 border-neutral-5 text-body1 text-neutral-3 font-semibold min-w-[210px]">
            Principal Account
          </p>
          <AutocompletedInput
            classInput="border-0 w-full"
            isDropdown={true}
            setIsFocus={setIsFocusTo}
            dropdownValue={dropdownValue}
            setIsValueInputPAccountSelectedTo={setIsValueInputPAccountSelectedTo}
            placehoderText={'Search Principal Account'}
            checkValueInput={setCheckValueInputPTo}
            handleClearPAccTo={handleClearPAccTo}
          />
        </div>
      </div>
      <div className="flex justify-end items-center">
        <div>
          <ButtonBase
            className="min-w-[108px] disabled:bg-primary-shade3"
            onClick={() => setIsModelOpen(true)}
            disabled={isDisable}
          >
            Group
          </ButtonBase>
          {dropdownValue.value === options[0].value &&
            isValueInputPAccountSelectedFrom?.idPrincipalAccountFrom &&
            isValueInputPAccountSelectedTo?.idPrincipalAccountTo && (
              <GroupPAccountToPAccount
                modalIsOpen={modalIsOpen}
                isValueInputPAccountSelectedFrom={isValueInputPAccountSelectedFrom}
                isValueInputPAccountSelectedTo={isValueInputPAccountSelectedTo}
                setIsDisable={setIsDisable}
                handleCloseDialog={() => {
                  setIsModelOpen(false)
                }}
                handleSubmitPrincipal={handleSubmitPrincipal}
              />
            )}
          {dropdownValue.value === options[1].value &&
            isValueInputPAccountSelectedTo?.idPrincipalAccountTo && (
              <GroupPrincipalToPAccount
                modalIsOpen={modalIsOpen}
                isValueInputPrincipalSelected={isValueInputPrincipalSelected}
                isValueInputPAccountSelectedTo={isValueInputPAccountSelectedTo}
                handleCloseDialog={() => {
                  setIsModelOpen(false)
                }}
                handleSubmitPrincipal={handleSubmitPrincipal}
              />
            )}
          {dropdownValue.value === options[1].value &&
            !isValueInputPAccountSelectedTo?.idPrincipalAccountTo && (
              <AddPrincipalAccount
                modalIsOpen={modalIsOpen}
                isValueInputPrincipalSelected={isValueInputPrincipalSelected}
                handleCloseDialog={() => {
                  setIsModelOpen(false)
                }}
              />
            )}
        </div>
      </div>
    </>
  )
}

export default GroupAccount
