import React from 'react'
import BaseDialog from './index'
import iconTimes from '../../assets/images/icon-times.svg'
import ButtonBase from '../partials/button'
import { defaultCustomStyles } from '../../constants/customStyles'

type Props = {
  modalIsOpen?: boolean
  title: string,
  confirmation: string,
  action: string,
  handleCloseDialog?: (val: boolean) => void
  handleAction?: () => void
  cancel?: string
}

const DialogBasic: React.FunctionComponent<Props> = ({
  modalIsOpen,
  title,
  confirmation,
  action,
  handleCloseDialog = () => null,
  handleAction = () => null,
  cancel
}) => {
  return (
    <BaseDialog modalIsOpen={modalIsOpen} customStyles={defaultCustomStyles}>
      <React.Fragment>
        <div className="flex justify-between">
          <div className="absolute right-4 top-4">
            <img
              src={iconTimes}
              alt=""
              className="cursor-pointer"
              onClick={() => handleCloseDialog(false)}
            />
          </div>
        </div>
        <div>
          <p className="mb-6 text-headline5 text-indicator-red text-center">{title}</p>
          <p className="mb-6 font-semibold text-body1 text-indicator-red text-center">
            {confirmation}
          </p>
          <div className="flex justify-between gap-3">
            <ButtonBase
              type="button"
              className="w-[180px] h-[52px] border-0 bg-primary-1 text-hairline1 min-w-min text-center text-neutral-8"
              onClick={() => handleCloseDialog(false)}
            >
              {cancel ?? 'Cancel'}
            </ButtonBase>
            <ButtonBase
              type="reset"
              className="w-[180px] h-[52px] text-hairline1 bg-neutral-8 border-2 border-primary-1 text-primary-1 hover:bg-primary-shade4"
              onClick={handleAction}
            >
              {action}
            </ButtonBase>
          </div>
        </div>
      </React.Fragment>
    </BaseDialog>
  )
}

export default DialogBasic
