import AdminPortal from './'
import { PageRoute } from '../../routes/routerInterface'
import adminPortalDetail from './containers/adminPortalDetail'
import { checkProdSite } from '../../helpers/checkProdSite'

const adminPortalRoutes: PageRoute[] = [
  {
    path: '/admin-portal',
    element: AdminPortal,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
  },
  {
    path: '/admin-portal/detail',
    element: adminPortalDetail,
    isProtected: true,
    permission: checkProdSite(true, 'Admin'),
  },
]

export default adminPortalRoutes
